import React from 'react'
import "./style.css"

function DownloadApp() {
  return (
    <div className='container'>
        <div className='downloadAppWrap'>
            <div className='appImage'><img src='/images/app-img.png'/></div>
            <div className='downloadContent'>
                <h3>The best</h3>
                <h1>ATTANDENCE APP</h1>
                <p>Attendance App is your ultimate solution for managing attendance seamlessly. Whether you’re a teacher tracking student attendance, an HR manager overseeing employees, or a team leader coordinating projects, our app simplifies your attendance recording and reporting needs.</p>
                <div className='actionsInfo'>
                    <div className='qrcode'><img src='/images/qr-code.svg'/></div>
                    <div className='downloadActionBtn'>
                        <a href='#'><img src='/images/google-pay.svg'/></a>
                        <a href='#'><img src='/images/Apple-Store.svg'/></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DownloadApp