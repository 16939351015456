import React from 'react'
import "./style.css"

const PrivacyPolicy = () => {
  return (
    <div className='container'>
      <div className='PrivacyPolicyWrap'>
        <h2>Privacy Policy</h2>
        <div className='PrivacyPolicyContent'>
          <p>Thank you for using our Attendance System application and web interface ("Myrollcall.pro"). This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our System.</p>
          <p>
            <strong>1. Information We Collect</strong>
          </p>

          <ul>
            <li><strong>Personal Information:</strong> <br/> <span className='subHeading'><strong>For Students :</strong> Name, email address, phone number, location data (collected every 15 minutes), parent/guardian contact details.</span><br/>
            <span className='subHeading'><strong>For Teachers :</strong> Name, email address, phone number, location data (at the start of each session).</span></li>
            <li><strong>Biometric Data :</strong> Selfies for face matching during attendance, which may be stored for future use. </li>
            <li><strong>Usage Information :</strong> IP addresses, browser type, operating system, referring URLs, access times, and device information.</li>
          </ul>

          <p><strong>2. How We Use Your Information</strong></p>
          <ul>
            <li>To facilitate attendance tracking and management.</li>
            <li>To authenticate users via OTP (One-Time Password) sent to email and phone numbers.</li>
            <li>To improve our System and user experience.</li>
            <li>To send notifications to parents/guardians regarding attendance and other relevant updates.</li>
            <li>For security purposes, including facial recognition for attendance verification.</li>
          </ul>
          <p><strong>3. Sharing of Information</strong></p>
          <ul>
            <li>Third-Party Services: We may share necessary information with third-party service providers for system operation, including cloud storage and communication services.</li>
            <li>Legal Compliance: We may disclose information when required by law or to protect our rights and safety.</li>
          </ul>
          <p><strong>4. Data Security</strong></p>
          <ul>
            <li>We implement reasonable security measures to protect your information, including encryption of data in transit and at rest.</li>
            <li>Access to personal information is restricted to authorized personnel only.</li>
           </ul>
          <p><strong>5. Your Choices</strong></p>
          <ul>
            <li>You can access and update your personal information through the System's settings.</li>
            <li>You can opt out of receiving non-essential communications.</li>
          </ul>
          <p>
            <strong>6. Children's Privacy</strong><br/>
            Our System is not intended for children under the age of 20. We do not knowingly collect personal information from children without parental consent.
          </p>
          <p>
            <strong>7. Contact Us</strong><br/>
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at +91-9888302322 | info@skyoIndia.com.
          </p>
          <p>
            <strong>8. Changes to This Privacy Policy</strong><br/>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website or app.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy