import React, { useEffect, useRef, useState } from 'react'
import AnimatedPage from '../AnimatedWrap/AnimatedPage'
import { motion } from "framer-motion"
import { baseUrl } from '../api/baseUrl';
import FinalStepModal from './finalStepModal';
import OnboardingModalInstitute from '../LoginEmail/onboardingModalInstitute';
import { useNavigate } from "react-router-dom"

const FinalQuestion = ({ checkEmailRegState, userEmail, userOnboarding, questionnaireCompleted }) => {

    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': 'w1sxc-d35fr-tg5bn-ji2k1',
    };

    const navigate = useNavigate()

    const [adminFirstName, setAdminFirstName] = useState("");
    const [adminPhoneNo, setAdminPhoneNo] = useState(checkEmailRegState.data.phone_number);
    const [designation, setDesignation] = useState("Administrator");
    const [errors, setErrors] = useState({});
    const [mobileAllreadyExist, setMobileAllreadyExist] = useState(false)
    const [mobileAllreadyExistError, setMobileAllreadyExistError] = useState(false)
    const [showSendOTP, setShowSendOTP] = useState(false);
    const [otp, setOtp] = useState(Array(6).fill(''));
    const inputRefs = useRef(Array(6).fill(null));
    const [otpError, setOtpError] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [verifyOtpSection, setVerifyOtpSection] = useState(false);
    const [otpVerify, setOtpVerify] = useState(false);
    const [finalStepModal, setFinalStepModal] = useState(false)
    const [sendOtpLoading, setSendOtpLoading] = useState(false)
    const [resendotp, setResendOtp] = useState(false)
    const [resendOtpLoading, setResendOtpLoading] = useState(false)
    const [loading, setloading] = useState(false);
    const [apiOtpError, setApiOtpError] = useState(null)


    useEffect(() => {
        if (checkEmailRegState.data.phone_number) {
            setOtpVerify(true)
        }
    }, [])



    const handleFirstNameChange = (e) => {
        setAdminFirstName(e.target.value);
        if (errors.adminFirstName) {
            setErrors((prevErrors) => ({ ...prevErrors, adminFirstName: "" }));
        }
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setOtpVerify(false)
        setOtpError(false)

        if (/^\d{0,10}$/.test(value)) {
            // Check if the first digit is between 6 and 9
            if (value.length > 0 && (value[0] < '6' || value[0] > '9')) {
                setErrors((prevErrors) => ({ ...prevErrors, adminPhoneNo: "Phone number is not valid" }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, adminPhoneNo: "" }));

                setAdminPhoneNo(value);
                setMobileAllreadyExistError(false);

                if (value.length === 10) {
                    setShowSendOTP(true);
                } else {
                    setShowSendOTP(false);
                    setVerifyOtpSection(false);
                    setOtp(Array(6).fill(''));
                }

                if (errors.adminPhoneNo) {
                    setErrors((prevErrors) => ({ ...prevErrors, adminPhoneNo: "" }));
                }
            }
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, adminPhoneNo: "Phone number is not valid" }));
        }

    };

    const handlePhoneKeyPress = (e) => {
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];
        if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
            e.preventDefault();
        }
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        let hasError = false;

        if (!adminFirstName) {
            setErrors((prevErrors) => ({ ...prevErrors, adminFirstName: "Name is required" }));
            hasError = true;
        }
        if (!adminPhoneNo || adminPhoneNo.length !== 10) {
            setErrors((prevErrors) => ({ ...prevErrors, adminPhoneNo: "Mobile number must be 10 digits" }));
            hasError = true;
        }
        if (!hasError) {
            setloading(true)
            localStorage.removeItem("rollcallQuestions");

            const json = {
                email: userEmail,
                phone_number: adminPhoneNo,
                is_phone_number_verified: true,
                username: adminFirstName,
                designation: designation,
                onboarding_completed: true,
                tnc_accepted: true

            }
            if (checkEmailRegState.data.phone_number) {
                try {
                    const res = await baseUrl.post("get-started", json, { headers });
                    if (res) {
                        setFinalStepModal(true);
                        setloading(false)
                    }
                } catch (err) {
                    console.log("err", err)
                    setloading(false)
                }
            } else {
                try {
                    const res = await baseUrl.get(`get-started/${adminPhoneNo}`, { headers });
                    if (res.data.code === "MRCP_1001") {
                        setMobileAllreadyExist(true)
                        setMobileAllreadyExistError(true)
                        setVerifyOtpSection(false)
                        setOtpVerify(false)
                        setShowSendOTP(false)
                        setOtp(Array(6).fill(''));
                        setloading(false)
                    } else {
                        try {
                            const res = await baseUrl.post("get-started", json, { headers })
                            if (res) {
                                setFinalStepModal(true)
                                setloading(false)
                            }
                        } catch (err) {
                            console.log("err", err)
                            setloading(false)
                        }
                    }
                } catch (err) {
                    alert("Server error: please try after sometime.")
                    setloading(false)
                }
            }
        }

    }

    const handleInputChange = (value, index) => {
        setApiOtpError(null)
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleverifyOtp = async () => {
        setOtpLoading(true)
        let enteredOtp = otp.join('');
        if (enteredOtp === "" || enteredOtp.length < 6) {
            setOtpError(true)
            setOtpLoading(false)
        } else {


            try {
                const myHeaders = {
                    "Content-Type": "application/json",
                    "x-api-key": "w1sxc-d35fr-tg5bn-ji2k1"
                };

                const data = {
                    "email_or_phone_no": adminPhoneNo,
                    "otp": enteredOtp
                };

                const res = await baseUrl.post('auth/verify-otp', data, {
                    headers: myHeaders
                });
                console.log("vaerify res", res.data)
                if (res.data.code === "MRCP_030") {   // opt expire
                    setOtp(Array(6).fill(''))
                    setApiOtpError(res.data.error)
                    setResendOtp(true)
                } else if (res.data.code === "MRCP_029") { // opt expire
                    setOtp(Array(6).fill(''))
                    setApiOtpError(res.data.error)
                } else if (res.data.code === "MRCP_1000") {
                    setOtpLoading(false);
                    setOtpError(false)
                    setOtpVerify(true)
                    setVerifyOtpSection(false)
                } else {
                    alert("something went Wrong! please try after some time")
                }

                setOtpLoading(false)
            } catch (err) {
                console.log("err", err)
                alert("something went Wrong! please try after some time")
                setOtpLoading(false)
            }
        }
    }

    const handleSendOtp = async () => {
        setSendOtpLoading(true)
        try {
            const myHeaders = {
                "Content-Type": "application/json",
                "x-api-key": "w1sxc-d35fr-tg5bn-ji2k1"
            };

            const data = {
                "email_or_phone_no": adminPhoneNo
            };
            const res = await baseUrl.get(`get-started/${adminPhoneNo}`, { headers });
            if (res.data.code === "MRCP_1001") {
                setMobileAllreadyExist(true)
                setMobileAllreadyExistError(true)
                setVerifyOtpSection(false)
                setOtpVerify(false)
                setShowSendOTP(false)
                setOtp(Array(6).fill(''));
                setloading(false)
            } else {
                const otpRes = await baseUrl.post(`auth/send-otp`, data, {
                    headers: myHeaders
                });
                if (otpRes) {
                    setShowSendOTP(false)
                    setVerifyOtpSection(false)
                    setVerifyOtpSection(true)
                }
            }

        } catch (error) {
            console.log("error", error)
            alert("Server serror: please try after sometime")
        } finally {
            setSendOtpLoading(false)
        }

    }


    const handleResendOtp = async () => {
        setResendOtpLoading(true)
        setApiOtpError(null)

        try {
            const myHeaders = {
                "Content-Type": "application/json",
                "x-api-key": "w1sxc-d35fr-tg5bn-ji2k1"
            };

            const data = {
                "email_or_phone_no": adminPhoneNo
            };
            const res = await baseUrl.post(`auth/send-otp`, data, {
                headers: myHeaders
            });

            if (res) {
                setResendOtp(false)
            }
        } catch (error) {
            console.log("error", error)
            alert("Server serror: please try after sometime")
        } finally {
            setResendOtpLoading(false);
        }
    }

    const handleBackButton = () => {
        navigate(`/general-questionaire?user-email=${userEmail}`)
    }

    return (

        <>
            <AnimatedPage>
                <div className='container mx-auto px-4'>
                    <div className='pt-5 relative z-20 mt-5 md:mt-12'>
                        <div className="grid gap-4 items-center mb-7 Thanksforsharing">

                            <div className='middle-columns'>
                                <motion.div initial={{ x: 50, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }} className='tellUs-form bg-white p-7 rounded-xl shadow-2xl shadow-slate-300'>
                                    {
                                        questionnaireCompleted && (
                                            <button type='button' onClick={handleBackButton} className={`GoBacktoQuestionnaire flex items-center`}>
                                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.14012 6.99999L16.8599 6.99999M16.8599 6.99999L11.1934 1.33499M16.8599 6.99999L11.1934 12.665" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> Go Back to Questionnaire
                                            </button>
                                        )
                                    }
                                    <img src="/images/thanku-for-your-information.svg" alt="" className="m-auto max-w-60" />
                                    <h3 className='text-center text-xl font-semibold mt-4'>Thanks for sharing the information.</h3>
                                    <h3 className='text-center text-md font-normal mb-8 text-gray-600'>To finalize your setup, please tell us a bit about yourself.</h3>
                                    <form onSubmit={onSubmitHandler} method='POST'>

                                        <div className="relative custom-input">
                                            <input
                                                type="text"
                                                id="floating_outlined"
                                                className="block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "
                                                name='admin_first_name'
                                                onChange={handleFirstNameChange}
                                            />

                                            <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 pointer-events-none">Name</label>

                                        </div>
                                        <span className="form-error-msg text-red-600 text-sm block mb-5">{errors?.adminFirstName}</span>


                                        <div className="relative custom-input">

                                            {

                                                mobileAllreadyExist ?
                                                    <input
                                                        type="text"
                                                        id="floating_outlined"
                                                        className="block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "
                                                        // {...register("admin_phone_no")}
                                                        name='admin_phone_no'
                                                        autoComplete="false"
                                                        onChange={handlePhoneChange}
                                                        maxLength={10}
                                                        onKeyDown={handlePhoneKeyPress}
                                                    />

                                                    :
                                                    checkEmailRegState.data.phone_number ? (
                                                        <input
                                                            type="number"
                                                            id="floating_outlined"
                                                            value={checkEmailRegState.data.phone_number}
                                                            className="block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "
                                                            // {...register("admin_phone_no")}
                                                            name="admin_phone_no"
                                                            autoComplete='false'
                                                            readOnly
                                                        />
                                                    ) :
                                                        <input
                                                            type="text"
                                                            id="floating_outlined"
                                                            className="block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "
                                                            name="admin_phone_no"
                                                            autoComplete='false'
                                                            onChange={handlePhoneChange}
                                                            maxLength={10}
                                                            onKeyDown={handlePhoneKeyPress}
                                                        />
                                            }

                                            <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 pointer-events-none">Mobile</label>
                                            <div className='otp-check-section'>

                                                {
                                                    showSendOTP && (
                                                        sendOtpLoading ?
                                                            <span className='showSendOTP'><span className='loading-state Small'></span></span>
                                                            :
                                                            <span className='showSendOTP' onClick={handleSendOtp}>Send OTP</span>
                                                    )

                                                }


                                                {otpVerify && <span className='showSendOTP Verified' onClick={handleSendOtp}>Verified ✔</span>}
                                            </div>
                                        </div>
                                        <span className="form-error-msg text-red-600 text-sm block mb-5">{errors?.adminPhoneNo}</span>
                                        {
                                            mobileAllreadyExistError && <span className="form-error-msg text-red-600 text-sm block mb-5">{"This mobile number already exist"}</span>
                                        }

                                        {
                                            verifyOtpSection &&
                                            <div className="otp-section space-x-2 flex justify-center pb-3 otpInput">
                                                <span className='otpLabel'>Enter OTP</span>
                                                {otp.map((digit, index) => (
                                                    <input
                                                        key={`${digit}-${index}`}
                                                        type="text"
                                                        maxLength="1"
                                                        className="w-12 h-12 text-2xl text-center border-b-2 border-gray-300  focus:outline-none"
                                                        value={digit}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*$/.test(value)) {
                                                                handleInputChange(value, index);
                                                                setOtpError(false);
                                                            }
                                                        }}
                                                        onKeyDown={e => handleKeyDown(e, index)}
                                                        ref={el => inputRefs.current[index] = el}
                                                        autoComplete="off"
                                                    />
                                                ))}
                                                {
                                                    resendotp ?
                                                        resendOtpLoading ?
                                                            <button type='button' className='flex items-center bg-blue-600 justify-center text-white gap-3 text-lg py-3 px-8 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300'>
                                                                <span className='loading-state Small'></span>
                                                            </button>
                                                            :
                                                            <button type='button' onClick={handleResendOtp} className='flex items-center bg-blue-600 justify-center text-white gap-3 text-lg py-3 px-8 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300'>
                                                                {"Resend Otp"}
                                                            </button>
                                                        :
                                                        otpLoading ?
                                                            <button type='button' className='flex items-center bg-blue-600 justify-center text-white gap-3 text-lg py-3 px-8 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300'>
                                                                <span className='loading-state Small'></span>
                                                            </button>
                                                            :
                                                            <button type='button' onClick={handleverifyOtp} className='flex items-center bg-blue-600 justify-center text-white gap-3 text-lg py-3 px-8 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300'>
                                                                {"Verify"}
                                                            </button>
                                                }

                                                <div className='otp-error'>
                                                    {
                                                        otpError && <p className="form-error-msg text-red-600 text-sm text-center mb-4 block">{"Please enter valid otp"}</p>
                                                    }
                                                    {apiOtpError && <p className="form-error-msg text-red-600 text-sm text-center mb-4 block">{apiOtpError}</p>}
                                                </div>
                                            </div>

                                        }



                                        <div className='designation select-container'>
                                            <select name='designation' onChange={(e) => setDesignation(e.target.value)} className='custom-select block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'>
                                                <option value={"Administrator"}>Administrator</option>
                                                <option value={"Coordinator"}>Coordinator</option>
                                                <option value={"HOD"}>HOD</option>
                                                <option value={"Dean"}>Dean</option>
                                                <option value={"Director"}>Director</option>
                                                <option value={"VC or Related"}>VC or Related</option>
                                            </select>
                                            <label htmlFor="state">Role</label>
                                        </div>
                                        <span className="form-error-msg text-red-600 text-sm block mb-5">{errors?.designation}</span>



                                        <div className='flex gap-4 items-center mt-8'>
                                            {
                                                loading ?
                                                    <button type='button' className='flex items-center bg-blue-600 justify-center text-white gap-3 text-lg py-3 px-8 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300'>
                                                        Saving <span className='loading-state Small'></span>
                                                    </button>
                                                    :

                                                    <button type='submit' style={!otpVerify && !checkEmailRegState.data.phone_number ? { pointerEvents: "none", opacity: 0.5 } : {}} className={`${!otpVerify ? "disabled" : ""} flex items-center bg-blue-600 justify-center text-white gap-3 text-lg py-3 px-8 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300`}>
                                                        Save
                                                    </button>

                                            }




                                            {/* <button type='submit' style={!otpVerify && !checkEmailRegState.data.phone_number ? { pointerEvents: "none", opacity: 0.5 } : {}} className={`${!otpVerify ? "disabled" : ""} flex items-center bg-blue-600 justify-center text-white gap-3 text-lg py-3 px-8 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300`}>
                                                Save
                                            </button> */}

                                        </div>
                                    </form>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimatedPage>

            {finalStepModal && <FinalStepModal />}

            {
                userOnboarding && <OnboardingModalInstitute />
            }
        </>

    )
}

export default FinalQuestion