import React, { useState, useCallback, useEffect } from 'react';

import { InlineWidget } from "react-calendly";
const Demo = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scrolling smooth
    });
  }, [])
  return (
    <>
      <div className=' mt-20'>
        <div className='container mx-auto px-4 md:pt-5 pt-10 relative z-20'>
          <div className='pageHeading'>
            <h2>Schedule a  free discovery call</h2>
          </div>
          <div className='grid grid-cols-12 gap-4 items-center'>
            <div className='lg:col-span-12 col-span-12' style={{ height: '700px', width: '100%', overflow: 'hidden' }}>
              <InlineWidget
                url="https://calendly.com/devanshi-skyoindia/discovery-call"
                styles={{ height: '100%', width: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Demo