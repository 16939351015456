
import { baseUrl } from "../api/baseUrl"

export const checkEmailRegistered = async (email) => {
    const res = await baseUrl.get(`get-started/${email}`, {
        headers: {
            'Cache-Control': 'no-store',
            'x-api-key': 'w1sxc-d35fr-tg5bn-ji2k1',
        }
    })
    if (res.data.code === "MRCP_1001") {
        return { status: true, data: res.data.data }
    } else {
        return { status: false, data: res.data.data }
    }

}

export const checkMobileRegistered = async (mobile) => {
    const res = await baseUrl.get(`get-started/${mobile}`, {
        headers: {
            'Cache-Control': 'no-store',
            'x-api-key': 'w1sxc-d35fr-tg5bn-ji2k1',
        }
    })
    if (res.data.code === "MRCP_1001") {
        return { status: true, data: res.data.data }
    } else {
        return { status: false, data: res.data.data }
    }

}