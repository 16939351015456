import React, { useState } from 'react'
import "./style.css"

const Faq = () => {

    const faqData = [
        {
            id: 1,
            question: "What is the Attendance System?",
            answer: "The Attendance System is a digital solution designed for schools and colleges to streamline attendance tracking processes using advanced technology."
        },
        {
            id: 2,
            question: "How does the system work?",
            answer: "The system uses location data from students and teachers, collected at regular intervals or session start times, alongside facial recognition technology for attendance verification."
        },
        {
            id: 3,
            question: "What information does the system collect?",
            answer: "We collect personal information such as names, email addresses, phone numbers, and location data from students and teachers. We also capture biometric data (selfies) for facial matching during attendance."
        },
        {
            id: 4,
            question: "How is my information protected?",
            answer: "We prioritize the security of your information. We use encryption protocols, access controls, and other industry-standard security measures to safeguard your data."
        },
        {
            id: 5,
            question: "Can parents access attendance information?",
            answer: "Yes, parents or guardians may receive notifications regarding their child's attendance status as part of our communication features."
        },
        {
            id: 6,
            question: "How can I update my personal information?",
            answer: "You can update your personal information through the settings section of the application or web interface."
        },
        {
            id: 7,
            question: "Is the system compliant with privacy laws?",
            answer: "Yes, we adhere to relevant data protection laws and regulations to ensure your privacy and security are protected."
        },
        {
            id: 8,
            question: "What happens to my data after I leave the institution?",
            answer: "Your data will be securely stored and maintained according to our retention policies. You may request deletion of your data upon leaving the institution."
        },
        {
            id: 9,
            question: "How can I contact support if I have questions?",
            answer: "For any questions or support needs, please contact us on +91-9888302322 or email on info@skyoIndia.com."
        },
        {
            id: 10,
            question: "Is the system suitable for different types of educational institutions?",
            answer: "Yes, our system is designed to be flexible and adaptable for various educational settings, including schools and colleges."
        }
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div className="container">
            <div className='faq-container'>
                <h2>Frequently Asked Questions</h2>
                <div className="accordion">
                    {faqData.map((item, index) => (
                        <div className="accordion-item" key={item.id}>
                            <button
                                onClick={() => toggleAccordion(index)}
                                aria-expanded={activeIndex === index ? "true" : "false"}>
                                <span className="accordion-title">{item.question}</span>
                                <span className="icon" aria-hidden="true"></span>
                            </button>
                            <div className="accordion-content" style={{ maxHeight: activeIndex === index ? "" : "none", display: activeIndex === index ? "block" : "none" }}>
                                <p>{item.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Faq