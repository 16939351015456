import React, { useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../api/baseUrl';

const QuestionComponent = ({ allQuestions, setQuestions, question, onChange, step, questions, handleNext, handlePrev, handleGeneralQuestiondata, userEmail, checkEmailRegState, reloadQuestions }) => {

    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': 'w1sxc-d35fr-tg5bn-ji2k1',
    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const questionId = queryParams.get('question-id');
    const [loading, setLoading] = useState(false)


    const navigate = useNavigate();
    const [error, setError] = useState(false)
    const [textFieldError, setTextFieldError] = useState(false)
    //     event.preventDefault();
    //     if (question.questionType === "final") {
    //         // const selectedOption = question.options.find((option) => option.selected);
    //         // console.log("selectedOption",selectedOption)
    //         // if (selectedOption) {
    //         //     onChange(selectedOption.label,"others");
    //         //     handleSubmit();
    //         //     setError(false)
    //         // } else {
    //         //     setError(true)
    //         // }

    //         const selectedOption = question.options.find((option) => option.selected);
    //         console.log("event.target", selectedOption)

    //         if (selectedOption) {
    //             onChange(selectedOption.label, "singal");
    //             handleSubmit();
    //             setError(false)
    //         } else {
    //             setError(true)
    //         }
    //     } else {
    //         const selectedOption = question.options.find((option) => option.selected);
    //         console.log("event.target", selectedOption)

    //         if (selectedOption) {
    //             onChange(selectedOption.label, "singal");
    //             handleNext();
    //             setError(false)
    //         } else {
    //             setError(true)
    //         }
    //     }

    // };

    const container = {
        hidden: { opacity: 1 },
        visible: {
            opacity: 1,
            transition: {
                delayChildren: 0.1,
                staggerChildren: 0.1
            }
        }
    };

    const item = {
        hidden: { x: 20, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1
        }
    };

    const onSubmitHandler = (event) => {
        event.preventDefault(); // Prevent form submission

        if (question.questionType === "final") {
            const selectedOption = question.options.find((option) => option.selected);
            console.log("selectedOption", selectedOption)

            if (selectedOption !== undefined && selectedOption.label !== "No" && question.text_needed && selectedOption.text == null) {
                setTextFieldError(true)
            }
            else if (selectedOption) {
                onChange(selectedOption.label);
                handleSubmit();
                setError(false)
            } else {
                setError(true)
            }
        } else {
            const selectedOption = question.options.find((option) => option.selected);
            console.log("selectedOption", selectedOption)

            if (selectedOption !== undefined && selectedOption.label !== "No" && question.text_needed && selectedOption.text == null) {
                setTextFieldError(true)
            }
            else if (selectedOption) {
                onChange(selectedOption.label);
                handleNext();
                setError(false)
                setTextFieldError(false)
            } else {
                setError(true)
                setTextFieldError(false)
            }
        }

    };



    const handleSubmit = async () => {
        setLoading(true)
        const selectedQuestions = allQuestions
            .map((item) => {
                const selectedOptions = item.options.filter((option) => option.selected === true);
                if (selectedOptions.length > 0) {
                    return {
                        ...item,
                        options: selectedOptions
                    };
                }
                return null;
            })
            .filter((item) => item !== null);

        const formData = selectedQuestions.map((item) => {
            return {
                question_id: item.id,
                response: item.options.map((option) => {
                    if (option.text) {
                        return `${option.label},${option.text}`;
                    } else {
                        return option.label;
                    }
                }).join(",")
            };
        })
        const institute_id = checkEmailRegState.data.id;
        const json = {
            email: userEmail,
            questionnaire_completed: true
        }

        const localQuestionObj = {
            [userEmail]: allQuestions
        }
        const jsonString = JSON.stringify(localQuestionObj);
        localStorage.setItem("rollcallQuestions", JSON.stringify(jsonString));

        try {
            const [quesResponse, getStarted] = await Promise.all([
                baseUrl.post(`institute/${institute_id}/questionnaire`, formData, { headers }),
                baseUrl.post(`get-started`, json, { headers })
            ]);
            if (quesResponse && getStarted) {
                navigate(`/user-details?user-email=${userEmail}`)
                setLoading(false)
            }
        } catch (err) {
            console.log("err", err)
        }
    }

    const handleSkip = async () => {
        setError(false)
        let updatedArr = [];
        if (reloadQuestions) {
            updatedArr = allQuestions.map((item) => {
                if (item.id === question.id) { // Replace 3 with the desired id to match
                    return {
                        ...item,
                        options: item.options.map(option => ({ ...option}))
                    };
                }
                return item;
            });
        } else {
            updatedArr = allQuestions.map((item) => {
                if (item.id === question.id) { // Replace 3 with the desired id to match
                    return {
                        ...item,
                        skip: true,
                        options: item.options.map(option => ({ ...option, selected: false }))
                    };
                }
                return item;
            });
        }

        setQuestions(updatedArr);
        console.log("updatedArr", updatedArr)

        if (question.questionType === "final") {
            console.log("final")
            const selectedQuestions = updatedArr
                .map((item) => {
                    const selectedOptions = item.options.filter((option) => option.selected === true);
                    if (selectedOptions.length > 0) {
                        return {
                            ...item,
                            skip: true,
                            options: selectedOptions
                        };
                    }
                    return null;
                })
                .filter((item) => item !== null);

            console.log("selectedQuestions", selectedQuestions)

            const formData = selectedQuestions.map((item) => {
                return {
                    question_id: item.id,
                    response: item.options.map((option) => {
                        if (option.text) {
                            return `${option.label},${option.text}`;
                        } else {
                            return option.label;
                        }
                    }).join(",")
                };
            })

            console.log("formData", formData)


            if (formData.length > 0) {
                const institute_id = checkEmailRegState.data.id;
                const json = {
                    email: userEmail,
                    questionnaire_completed: true
                }
                const localQuestionObj = {
                    [userEmail]: updatedArr
                }
                const jsonString = JSON.stringify(localQuestionObj);
                localStorage.setItem("rollcallQuestions", JSON.stringify(jsonString));

                try {
                    const [quesResponse, getStarted] = await Promise.all([
                        baseUrl.post(`institute/${institute_id}/questionnaire`, formData, { headers }),
                        baseUrl.post(`get-started`, json, { headers })
                    ]);
                    if (quesResponse && getStarted) {
                        navigate(`/user-details?user-email=${userEmail}`)
                        setLoading(false)
                    }
                } catch (err) {
                    console.log("err", err)
                }

            } else {
                navigate(`/user-details?user-email=${userEmail}`)
            }
        } else {
            handleNext()

        }
    }

    const inputTextValue = useMemo(() => {
        return question.options.find((item) => item.selected === true)
    }, [question, step, onChange])

    const renderOptions = useMemo(() => {
        if (!question) return null;

        if (!question.multi_select) {
            if (question.options_separated_by_comma !== null) {
                return (
                    <>
                        {question.options.map((option, index) => (
                            <motion.li key={index} className='item mb-4' variants={item}>
                                <label htmlFor={`${step}-${index}`} className='relative overflow-hidden w-full max-w-lg flex mx-auto p-5 bg-blue-50 ps-14 rounded-xl cursor-pointer'>
                                    <input
                                        type='radio'
                                        id={`${step}-${index}`}
                                        className='sr-only peer'
                                        value={option.label}
                                        checked={option.selected}
                                        onChange={() => {
                                            onChange(option.label, "single");
                                            setError(false);
                                            setTextFieldError(false);
                                        }}
                                    />
                                    <i className='absolute left-0 top-0 w-full h-full bg-blue-200 -z-0 hidden transition-all peer-checked:block'></i>
                                    <span className='z-10 w-6 h-6 bg-transparent border-2 border-gray-300 flex justify-center items-center absolute left-5 top-5 rounded-full transition ease-in-out duration-200'></span>
                                    <span className='z-10 w-6 h-6 bg-blue-700 flex justify-center items-center absolute left-5 top-5 rounded-full scale-0 peer-checked:scale-100 transition ease-in-out duration-200'>
                                        <img src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC43NSAxTDUuNzg0NDggNi40MzY5OEM1LjA5ODg5IDcuMTg3NjcgMy45ODczMiA3LjE4NzY3IDMuMzAxNzIgNi40MzY5OEwxLjc1IDQuNzM3OTMiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=' alt='' />
                                    </span>
                                    <span className='relative z-10 peer-checked:text-blue-600 peer-checked:font-medium GeneralLabel'>{option.label}</span>
                                </label>
                            </motion.li>
                        ))}

                        {question.addInput && (
                            <div className="relative mb-5 w-full max-w-lg m-auto custom-input">
                                <input
                                    type="text" id="floating_outlined"
                                    className="block px-2 py-3.5 text-sm w-full text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    onChange={(e) => {
                                        onChange(e.target.value, "single-text");
                                        setError(false);
                                        setTextFieldError(false);
                                    }}
                                    value={inputTextValue?.text || ""}
                                />
                                <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 pointer-events-none">{question.question}</label>
                            </div>
                        )}
                    </>
                );
            } else {
                return (
                    <motion.li className='item mb-4' variants={item}>
                        <div className="relative mb-5 w-full max-w-lg m-auto ">
                            <input
                                type="text" id="floating_outlined"
                                className="block px-2 py-3.5 text-sm w-full text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(e) => {
                                    onChange(e.target.value, "text");
                                    setError(false);
                                }}
                            />
                            <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 pointer-events-none">{question.question}</label>
                        </div>
                    </motion.li>
                );
            }
        } else {
            return question.options.map((option, index) => (
                <motion.li key={index} className='item mb-4' variants={item}>
                    <label htmlFor={`${step}-${index}`} className='relative overflow-hidden w-full max-w-lg flex mx-auto p-5 bg-blue-50 ps-14 rounded-xl cursor-pointer'>
                        <input
                            type='checkbox'
                            id={`${step}-${index}`}
                            className='sr-only peer'
                            value={option.label}
                            checked={option.selected}
                            onChange={() => {
                                onChange(option.label, "multi", "");
                                setError(false);
                            }}
                        />
                        <i className='absolute left-0 top-0 w-full h-full bg-blue-200 -z-0 hidden transition-all peer-checked:block'></i>
                        <span className='z-10 w-6 h-6 bg-transparent border-2 border-gray-300 flex justify-center items-center absolute left-5 top-5 rounded-full transition ease-in-out duration-200'></span>
                        <span className='z-10 w-6 h-6 bg-blue-700 flex justify-center items-center absolute left-5 top-5 rounded-full scale-0 peer-checked:scale-100 transition ease-in-out duration-200'>
                            <img src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC43NSAxTDUuNzg0NDggNi40MzY5OEM1LjA5ODg5IDcuMTg3NjcgMy45ODczMiA3LjE4NzY3IDMuMzAxNzIgNi40MzY5OEwxLjc1IDQuNzM3OTMiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=' alt='' />
                        </span>
                        <span className='relative z-10 peer-checked:text-blue-600 peer-checked:font-medium'>{option.label}</span>
                    </label>
                </motion.li>
            ));
        }
    }, [question, step, onChange, setError, setTextFieldError]);



    return (
        <div className='text-center md:mt-28 mt-10'>
            <h2 className='md:text-4xl text-lg font-semibold text-blue-900 w-full md:max-w-3xl m-auto'>{question.text}</h2>
            <form onSubmit={onSubmitHandler}>
                <div className='question-wrap-box mt-20 mb-10'>
                    <motion.ul
                        variants={container}
                        initial="hidden"
                        animate="visible"
                        key={step}
                    >

                        {renderOptions}


                    </motion.ul>

                    {/* {
                        question?.addQuestion && (
                            <div className="relative mb-5 w-full max-w-lg m-auto ">
                                <input
                                    type="text" id="floating_outlined"
                                    className="block px-2 py-3.5 text-sm w-full text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    onChange={(e) => handleOnChangeInput(e.target.value, question.id)}
                                    onClick={() => handleClickInput(question.id)}
                                />
                                <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 pointer-events-none">Institute Size</label>
                            </div>
                        )
                    } */}

                    {
                        error && <span className="form-error-msg text-red-600 text-sm block text-center">{"Atleast one option must be selected"}</span>
                    }

                    {
                        textFieldError && <span className="form-error-msg text-red-600 text-sm block text-center">{"Please enter"}</span>
                    }

                    <div className='flex justify-center mt-10 gap-5'>
                        {step > 0 && !questionId && !questionId &&
                            <button type='button' onClick={handlePrev} className='backqustion flex items-center  justify-center text-gray-400 border border-gray-400 gap-3 text-lg py-2 px-6 rounded-lg hover:bg-gray-200 transition ease-in-out duration-300'>
                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.14012 6.99999L16.8599 6.99999M16.8599 6.99999L11.1934 1.33499M16.8599 6.99999L11.1934 12.665" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> Back
                            </button>
                        }

                        <button type='button' onClick={handleSkip} className='flex items-center  justify-center text-gray-400 border border-gray-400 gap-3 text-lg py-2 px-6 rounded-lg hover:bg-gray-200 transition ease-in-out duration-300'>
                            Skip
                        </button>
                        {/* <button type='submit' className='bg-blue-500 text-white py-2 px-4 rounded'>

                        </button> */}





                        {
                            step < questions.length - 1 ?
                                <>
                                    <button type='submit' className='flex items-center bg-blue-600 mt-0 justify-center text-white gap-3 text-lg py-2 px-6 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300'>
                                        Continue  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.14012 6.99999L16.8599 6.99999M16.8599 6.99999L11.1934 1.33499M16.8599 6.99999L11.1934 12.665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </>
                                :
                                loading ?
                                    <button type='submit' className='flex items-center bg-blue-600 mt-0 justify-center text-white gap-3 text-lg py-2 px-6 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300'>
                                        Saving.. <span className='loading-state Small'></span>
                                    </button>
                                    :
                                    <button type='submit' className='flex items-center bg-blue-600 mt-0 justify-center text-white gap-3 text-lg py-2 px-6 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300'>
                                        Submit  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.14012 6.99999L16.8599 6.99999M16.8599 6.99999L11.1934 1.33499M16.8599 6.99999L11.1934 12.665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>



                        }
                    </div>
                </div>
            </form>
        </div>
    );
};

export default QuestionComponent;
