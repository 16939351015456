import React, { useEffect, useState } from 'react';
import './style.css';
import AnimatedPage from '../../Components/AnimatedWrap/AnimatedPage';
import QuestionComponent from '../../Components/QuestionsComp';
import { baseUrl } from '../../Components/api/baseUrl';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkEmailRegistered } from '../../Components/common/checkEmailVerify';
import OnboardingModalInstitute from '../../Components/LoginEmail/onboardingModalInstitute';


const GeneralQuestionnaire = () => {

  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': 'w1sxc-d35fr-tg5bn-ji2k1',
  };

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const userEmail = queryParams.get('user-email');
  const [useEmailRegister, setUseEmailRegister] = useState(false);
  const [userOnboarding, setUserOnboarding] = useState(false)
  const [checkEmailRegState, setcheckEmailRegState] = useState(null)
  const [questionnaireCompleted, setQuestionnaireCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reloadQuestions, setReloadQuestions] = useState(false)


  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState(Array(5).fill(''));

  // const [questions, setQuestions] = useState([
  //   {
  //     id: 1,
  //     text: "Usual length of lecture in time unit?",
  //     multi_select: true,
  //     options: [
  //       { label: "Less than 30 minutes", selected: false },
  //       { label: "30-60 minutes", selected: false },
  //       { label: "60-90 minutes", selected: false },
  //       { label: "More than 90 minutes", selected: false }
  //     ]
  //   },
  //   {
  //     id: 2,
  //     text: "How many programs do you run?",
  //     multi_select: false,
  //     options: [
  //       { label: "Less than 5", selected: false },
  //       { label: "5-10", selected: false },
  //       { label: "More than 10", selected: false }
  //     ]
  //   },
  //   {
  //     id: 3,
  //     text: "Are there sections for classes (e.g., Section A, Section B)?",
  //     multi_select: false,
  //     options: [
  //       { label: "Yes", selected: false },
  //       { label: "No", selected: false }
  //     ]
  //   },
  //   {
  //     id: 4,
  //     text: "Are there any vocational or extracurricular classes that require attendance tracking?",
  //     multi_select: false,
  //     options: [
  //       { label: "Yes", selected: false },
  //       { label: "No", selected: false },
  //     ]
  //   },
  //   {
  //     id: 5,
  //     text: "Are your classes mostly on-campus or online?",
  //     multi_select: true,
  //     options: [
  //       { label: "On-campus", selected: false },
  //       { label: "Online", selected: false },
  //       { label: "Both", selected: false }
  //     ]
  //   },
  //   {
  //     id: 6,
  //     text: "Do you use any existing attendance recording systems? ",
  //     multi_select: false,
  //     options: [
  //       { label: "Yes (Please specify)", selected: false },
  //       { label: "No", selected: false }
  //     ]
  //   },
  //   {
  //     id: 7,
  //     text: "What are your biggest challenges with the current attendance system?",
  //     multi_select: false,
  //     questionType: "final",
  //     options: [
  //       { label: "Accuracy", selected: false },
  //       { label: "Time-consuming", selected: false },
  //       { label: "Integration with other systems", selected: false },
  //     ]
  //   },

  //   // {
  //   //   id: 7,
  //   //   text: "Are your classes mostly on-campus or online?",
  //   //   options: [
  //   //     { label: "On-campus", selected: false },
  //   //     { label: "Online", selected: false },
  //   //     { label: "Both", selected: false }
  //   //   ]
  //   // },
  //   // {
  //   //   id: 8,
  //   //   text: "Are your classes mostly on-campus or online?",
  //   //   options: [
  //   //     { label: "On-campus", selected: false },
  //   //     { label: "Online", selected: false },
  //   //     { label: "Both", selected: false }
  //   //   ]
  //   // },
  //   // {
  //   //   id: 9,
  //   //   text: "Are your classes mostly on-campus or online?",
  //   //   options: [
  //   //     { label: "On-campus", selected: false },
  //   //     { label: "Online", selected: false },
  //   //     { label: "Both", selected: false }
  //   //   ]
  //   // },
  //   // {
  //   //   id: 10,
  //   //   text: "Are your classes mostly on-campus or online?",
  //   //   options: [
  //   //     { label: "On-campus", selected: false },
  //   //     { label: "Online", selected: false },
  //   //     { label: "Both", selected: false }
  //   //   ]
  //   // },
  //   // {
  //   //   id: 11,
  //   //   text: "Are your classes mostly on-campus or online?",
  //   //   options: [
  //   //     { label: "On-campus", selected: false },
  //   //     { label: "Online", selected: false },
  //   //     { label: "Both", selected: false }
  //   //   ]
  //   // },
  //   // {
  //   //   id: 12,
  //   //   text: "Are your classes mostly on-campus or online?",
  //   //   options: [
  //   //     { label: "On-campus", selected: false },
  //   //     { label: "Online", selected: false },
  //   //     { label: "Both", selected: false }
  //   //   ]
  //   // },
  // ]);

  const [questions, setQuestions] = useState([]);


  const callApiGetQuestion = async (emailData) => {
    setLoading(true);
    try {
      const data = await baseUrl.get("questionnaire", { headers });
      const originalArray = data.data.data;
      // originalArray.pop()

      const updatedArray = originalArray.map((item, index) => {
        let options = [{ label: "", selected: false }];

        if (item.options_separated_by_comma) {
          options = item.options_separated_by_comma.split(',').map(option => ({
            label: option.trim(),
            selected: false
          }));
        }

        // Add questionType: "final" only to the last object in the array
        if (index === originalArray.length - 1) {
          return {
            ...item,
            id: item.id,
            text: item.question,
            options: options,
            questionType: "final"
          };
        } else {
          return {
            ...item,
            id: item.id,
            text: item.question,
            options: options
          };
        }
      });

      if (emailData.data.questionnaire_completed) {
        const institut_id = emailData.data.id;

        try {
          const res = await baseUrl.get(`institute/${institut_id}/questionnaire`, { headers });
          setReloadQuestions(true)
          setLoading(false);
          updatedArray.forEach(question => {
            // Find the corresponding response from arr2
            const response = res.data.data.find(resp => resp.question_id === question.id);

            if (response) {
              // Split the response by comma to handle multiple selections
              const responses = response.response.split(',');
              // if (question.text_needed) {
              //   question.addInput = true
              // }
              // Update the selected field of the options
              question.options.forEach(option => {
                if (question.text_needed && option.label === "Yes (Please specify)" && responses.includes(option.label)) {
                  // Ensure the text key is added
                  option.text = responses[1];
                  question.addInput = true
                }
                if (responses.includes(option.label)) {
                  option.selected = true;
                } else {
                  option.selected = false; // Ensure other options are not selected
                }
              });
            } else {
              question.skip = true;
            }
          });
          setQuestions(updatedArray)
        } catch (err) {
          setLoading(false);
          alert("server error: please try sometime!")
        }
      } else {
        setQuestions(updatedArray)
        setLoading(false);

      }

    } catch (err) {
      setLoading(false);
      alert("server error: please try sometime!")
    }

  }

  const getQuestions = async (email) => {
    setLoading(true);
    const checkEmailReg = await checkEmailRegistered(email);
    setcheckEmailRegState(checkEmailReg)
    if (checkEmailReg.data.onboarding_completed) {
      setUserOnboarding(true)
    } else {
      setUserOnboarding(false)
    }
    if (checkEmailReg.data.is_registered) {
      setUseEmailRegister(true)
    } else {
      setUseEmailRegister(false)
    }


    const retrievedData = localStorage.getItem('rollcallQuestions');
    const parsedData = JSON.parse(retrievedData);
    const qa = JSON.parse(parsedData);

    if (qa) {
      const emailExists = userEmail in qa;
      if (emailExists) {
        setReloadQuestions(true)
        setQuestions(qa[userEmail])
        setLoading(false)
      } else {
        callApiGetQuestion(checkEmailReg)
      }

    } else {
      callApiGetQuestion(checkEmailReg)

    }

  }



  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleFormChange = (answer, type) => {

    const updatedQuestions = [...questions];
    const currentOptions = updatedQuestions[step].options;
    updatedQuestions[step].skip = false;

    if (type === "single") {
      currentOptions.forEach((option) => {
        option.selected = option.label === answer;

        if (answer !== "No" && updatedQuestions[step].text_needed === true) {
          updatedQuestions[step].addInput = true;
          option.text = null;
        } else {
          updatedQuestions[step].addInput = false;
        }
      });
    } else if (type === "multi") {
      currentOptions.forEach((option) => {
        if (option.label === answer) {
          option.selected = !option.selected;
        }
      });
    } else if (type === "single-text") {
      currentOptions.forEach((option) => {
        if (option.selected) {
          option.text = answer;
        }
      });
    } else if (type === "text") {
      currentOptions.forEach((option) => {
        if (answer !== "") {
          option.label = answer;
          option.selected = true
        } else {
          option.label = answer;
          option.selected = false
        }
      });
    } else {
      console.log("currentOptions", currentOptions);
    }

    setQuestions(updatedQuestions);
  };


  useEffect(() => {
    getQuestions(userEmail);
  }, [userEmail])


  if (loading) {
    return <div className='loading-state'>Loading...</div>
  }
  if (useEmailRegister) {
    return (
      <AnimatedPage>
        <div className='container mx-auto px-4 GeneralQuestionnaire'>
          <h3 className='md:text-right text-center text-xl font-semibold text-regal-blue'>General Questionnaire</h3>
          <div className='flex gap-4 items-center mt-10 progressWrapper'>

            {questions.map((item, index) => (
              <div key={index} className={`progressbar-1 bg-white h-2 rounded-xl relative overflow-hidden w-full`}>
                <span className={`${item?.skip ? "bg-red-500" : "bg-blue-500"} absolute h-2 ${step >= index ? 'w-full' : 'w-0'} rounded-xl transition-all transition-width duration-slow ease-out`}></span>
              </div>
            ))}
          </div>

          {
            questions.length > 0 && (
              <QuestionComponent
                question={questions[step]}
                data={formData[step]}
                onChange={handleFormChange}
                step={step}
                questions={questions}
                handleNext={handleNext}
                handlePrev={handlePrev}
                allQuestions={questions}
                setQuestions={setQuestions}
                userEmail={userEmail}
                checkEmailRegState={checkEmailRegState}
                reloadQuestions={reloadQuestions}
              />
            )
          }
        </div>

        {
          userOnboarding && <OnboardingModalInstitute />
        }
      </AnimatedPage>
    )
  }
  navigate("/");
};

export default GeneralQuestionnaire;
