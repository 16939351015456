import React, { useEffect, useMemo, useRef, useState } from 'react';
import TellUsImg from '../../images/give-address-img.svg';
import AnimatedPage from '../../Components/AnimatedWrap/AnimatedPage';
import { motion } from "framer-motion";
import { useLocation, useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { indiaColleges } from '../../data/indiaColleges';
import "./style.css"
import { checkEmailRegistered } from '../../Components/common/checkEmailVerify';
import { baseUrl } from '../../Components/api/baseUrl';
import OnboardingModalInstitute from '../../Components/LoginEmail/onboardingModalInstitute';
import VerifyEmailPopup from '../../Components/common/VerifyEmailPopup';

const InstituteAddress = () => {
    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': 'w1sxc-d35fr-tg5bn-ji2k1',
    };

    const [formData, setFormData] = useState({
        name: '',
        country: 'India',
        state: '',
        city: '',
        size: '',
        otherCollegeName: '',
    });

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const userEmail = queryParams.get('user-email');
    const [useEmailRegister, setUseEmailRegister] = useState(false);
    const [checkEmailRegState, setcheckEmailRegState] = useState(null)
    const [userOnboarding, setUserOnboarding] = useState(false)
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [generalQuestionModal, setGeneralQuestionModal] = useState(false)

    const inputRef = useRef(null);
    const [errors, setErrors] = useState({});

    const [countries, setCountries] = useState(null);
    const [states, setStates] = useState([]);
    const [query, setQuery] = useState("");
    const [collegeSelected, setCollegeSelected] = useState(false);
    const [filteredColleges, setFilteredColleges] = useState(indiaColleges)

    const [verifiedEmailModal, setVerifiedEmailModal] = useState(false)
    const [verifiedEmailOtpModal, setVerifiedEmailOtpModal] = useState(false)
    const [sendEmailLoading, setSendEmailLoading] = useState(false)

    const { name, country, state, city, size, otherCollegeName } = formData;

    const selectedStateCity = useMemo(() => {
        const stateData = states.find(s => s.name === state);
        if (stateData !== undefined) {
            return City.getCitiesOfState(countries.isoCode, stateData.isoCode);

        }
    }, [state]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "name") {
            setCollegeSelected(false)
            const value = e.target.value;
            setQuery(value);
            const filtered = indiaColleges.filter((college) =>
                college.toLowerCase().includes(value.toLowerCase())
            );
            if (value.trim() !== "") {
                filtered.push("Others");
            }
            setFilteredColleges(filtered);
        }
        setFormData({
            ...formData,
            [name]: value
        });

        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
    };

    const validateField = (name, value) => {
        let error = '';

        switch (name) {
            case 'name':
                if (!value) {
                    error = 'Institute name is required';
                }
                break;
            case 'country':
                if (!value) {
                    error = 'Country is required';
                }
                break;
            case 'state':
                if (!value) {
                    error = 'State is required';
                }
                break;
            case 'city':
                if (!value) {
                    error = 'City is required';
                }
                break;
            case 'size':
                if (!value) {
                    error = 'Institute size is required';
                }
                break;
            case 'otherCollegeName':
                if (formData.name === "Others" && !value) {
                    error = 'Institute full name is required';
                }
                break;
            default:
                break;
        }

        return error;
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            const error = validateField(key, formData[key]);
            if (error) {
                newErrors[key] = error;
            }
        });
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        }
        else {

            calladdresApi(formData)
        }
    };


    const calladdresApi = async (data) => {
        setSubmitLoading(true)
        console.log("checkEmailRegStatecheckEmailRegState", checkEmailRegState.data.is_email_verified)
        if (data.name === "Others") {
            const json = {
                email: userEmail,
                institute_name: data.otherCollegeName,
                country: data.country,
                state: data.state,
                city: data.city,
                size: data.size
            }

            try {
                const res = await baseUrl.post("get-started", json, { headers })
                if (res) {
                    if (checkEmailRegState.data.is_email_verified) {
                        setSubmitLoading(false)
                        setGeneralQuestionModal(true)
                    } else {
                        setVerifiedEmailModal(true)
                        setSubmitLoading(false)
                    }

                }

            } catch (err) {
                console.log("err", err);
                setSubmitLoading(false);
            }
        } else {
            const json = {
                email: userEmail,
                institute_name: data.name,
                country: data.country,
                state: data.state,
                city: data.city,
                size: data.size
            }
            try {
                const res = await baseUrl.post("get-started", json, { headers })
                if (res) {
                    if (checkEmailRegState.data.is_email_verified) {
                        setSubmitLoading(false)
                        if (!checkEmailRegState.data.questionnaire_completed) {
                            setGeneralQuestionModal(true)
                        } else {
                            navigate(`/user-details?user-email=${userEmail}`)
                        }
                    } else {
                        setVerifiedEmailModal(true)
                        setSubmitLoading(false)
                    }


                }

            } catch (err) {
                console.log("err", err);
                setSubmitLoading(false);
            }
        }
    }

    const handleCollegeClick = (college) => {
        setQuery("");
        setFormData(prevFormData => ({
            ...prevFormData,
            name: college,
        }));
        setErrors({
            ...errors,
            ["otherCollegeName"]: ''
        });
        setCollegeSelected(true)
        setFilteredColleges([]);
    };

    const checkEmailReg = async (email) => {
        setLoading(true);
        const checkEmailReg = await checkEmailRegistered(email);
        if (checkEmailReg.data.onboarding_completed) {
            setUserOnboarding(true)
        } else {
            setUserOnboarding(false)
        }
        if (checkEmailReg.data.institute_name) {
            const addressObj = {
                name: checkEmailReg.data.institute_name || "",
                country: 'India',
                state: checkEmailReg.data.state || "",
                city: checkEmailReg.data.city || "",
                size: checkEmailReg.data.size || "",
                otherCollegeName: ''
            }
            setFormData(addressObj);
            setCollegeSelected(true)
        }
        setcheckEmailRegState(checkEmailReg)
        if (checkEmailReg.data.is_registered) {
            setUseEmailRegister(true)
        } else {
            setUseEmailRegister(false)
        }
        setLoading(false);
    }

    const handleGeneralContinue = () => {
        navigate(`/general-questionaire?user-email=${userEmail}`);
    }

    const handleGeneralSkip = () => {
        navigate(`/user-details?user-email=${userEmail}`)
    }

    const handlePopUp = () => {
        setVerifiedEmailModal(!verifiedEmailModal)
    }


    const handleClick = async () => {
        setSendEmailLoading(true)
        try {
            const myHeaders = {
                "Content-Type": "application/json",
                "x-api-key": "w1sxc-d35fr-tg5bn-ji2k1"
            };

            const data = {
                "email_or_phone_no": userEmail
            };
            const res = await baseUrl.post(`auth/send-otp`, data, {
                headers: myHeaders
            });
            if (res) {
                setVerifiedEmailOtpModal(true);
                setVerifiedEmailModal(false)
                setSendEmailLoading(false)
            }
        } catch (err) {
            setSendEmailLoading(false)

        }

    }

    const handleClose = () => {
        setVerifiedEmailOtpModal(false)
    }
    const handleVerifyOtpEmail = () => {
        setGeneralQuestionModal(true)
    }

    useEffect(() => {
        const countriesArr = Country.getAllCountries();
        setCountries(countriesArr)
        const country = countriesArr.find(c => c.name === "India");
        setCountries(country)
        const statesArr = State.getStatesOfCountry(country?.isoCode);
        setStates(statesArr)

        if (userEmail) {
            checkEmailReg(userEmail);
        }
    }, [userEmail]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setFilteredColleges([]);
                setQuery("");
                // setFormData(prevFormData => ({
                //     ...prevFormData,
                //     name: "",
                // }));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [inputRef]);

    if (loading) {
        return <div className='loading-state'>Loading...</div>
    }
    if (useEmailRegister) {
        return (
            <>
                <AnimatedPage>
                    <div className='container mx-auto px-4'>
                        <div className='pt-5 relative z-20 mt-5 md:mt-12'>
                            <div className="InstituteWrapper InstituteInformation">
                                <div className='ImageBlock'>
                                    <motion.div className='img-thumbnail' initial={{ x: -50, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }}>
                                        <img src={TellUsImg} alt='Tell Us' />
                                    </motion.div>
                                </div>

                                <div className='InfoBox' ref={inputRef}>
                                    <motion.div initial={{ x: 50, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }} className='tellUs-form bg-white p-7 rounded-xl shadow-2xl shadow-slate-300'>
                                        <h3 className='text-center text-xl font-semibold'>Institute Information</h3>
                                        <h3 className='text-center text-md font-normal mb-8 text-gray-600'>Please provide your institute's details.</h3>
                                        <form className='InstituteInformation' onSubmit={onSubmitHandler} >

                                            <div className="relative custom-input">
                                                <input
                                                    type="text"
                                                    id="floating_outlined"
                                                    className="block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "
                                                    name='name'
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    value={name}
                                                />
                                                <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 pointer-events-none">Institute Full Name</label>
                                                {query && (
                                                    <ul>
                                                        {filteredColleges.map((college, index) => (
                                                            <li key={college} onClick={() => handleCollegeClick(college)}>{college}</li>
                                                        ))}
                                                    </ul>
                                                )}

                                            </div>
                                            <span className="form-error-msg text-red-600 text-sm block mb-5">{errors?.name}</span>

                                            {name === "Others" && (
                                                <div className="relative custom-input">
                                                    <input
                                                        type="text"
                                                        id="floating_outlined"
                                                        className="block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                        placeholder=" "
                                                        name='otherCollegeName'
                                                        onChange={handleInputChange}
                                                        autoComplete='off'
                                                        value={otherCollegeName}
                                                    />
                                                    <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 pointer-events-none">Others</label>
                                                </div>
                                            )}
                                            <span className="form-error-msg text-red-600 text-sm block mb-5">{errors?.otherCollegeName}</span>

                                            <div className='select-container'>
                                                <select
                                                    id="countries"
                                                    className="custom-select block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                    name='country'
                                                    defaultValue={country}
                                                >
                                                    <option value="India">India</option>
                                                </select>
                                                <label htmlFor="countries">Country</label>
                                            </div>


                                            <div className='cityAndState'>
                                                <div className='StateWrap select-container'>
                                                    <select
                                                        id="state"
                                                        className="custom-select block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                        name='state'
                                                        defaultValue={state}
                                                        onChange={handleInputChange}
                                                        autoComplete='off'
                                                    >
                                                        <option value="" disabled>State</option>
                                                        {
                                                            states.map((item) => (
                                                                <option value={item.name} key={item.name}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {
                                                        state && <label htmlFor="state">State</label>
                                                    }
                                                    <span className="form-error-msg text-red-600 text-sm block mb-5">{errors?.state}</span>
                                                </div>
                                                <div className='CityWrap select-container'>
                                                    <select
                                                        id="city"
                                                        className="custom-select block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                        name='city'
                                                        defaultValue={city}
                                                        onChange={handleInputChange}
                                                        autoComplete='off'
                                                    >
                                                        <option value="" disabled>City</option>
                                                        {
                                                            selectedStateCity?.map((item) => (
                                                                <option value={item.name} key={item.name}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {
                                                        city && <label htmlFor="city">City</label>
                                                    }
                                                    <span className="form-error-msg text-red-600 text-sm block mb-5">{errors?.city}</span>
                                                </div>
                                            </div>

                                            <div className="relative select-container">
                                                <select
                                                    id="instituteSize"
                                                    className="custom-select block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                    name='size'
                                                    defaultValue={size}
                                                    onChange={handleInputChange}

                                                >
                                                    <option value="" disabled>Institute Size</option>
                                                    <option value="0-1000">{"0-1000"}</option>
                                                    <option value="1000-5000">{"1000-5000"}</option>
                                                    <option value="5000-10000">{"5000-10000"}</option>
                                                    <option value=">10000">{">10000"}</option>
                                                </select>
                                                {
                                                    size && <label htmlFor="instituteSize">Institute Size</label>
                                                }
                                            </div>
                                            <span className="form-error-msg text-red-600 text-sm block mb-5">{errors?.size}</span>

                                            <div className='flex gap-4 items-center mt-8'>
                                                {
                                                    submitLoading ?
                                                        <button type='button' className='flex items-center bg-blue-600 justify-center text-white gap-3 text-lg py-3 px-8 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300'>
                                                            Continue <span className='loading-state Small'></span>
                                                        </button>
                                                        :

                                                        <button
                                                            type='submit'
                                                            className={`${!collegeSelected ? "disabled" : ""} flex items-center bg-blue-600 justify-center text-white gap-3 text-lg py-3 px-8 rounded-lg hover:bg-regal-blue transition ease-in-out duration-300`}

                                                            // disabled={!collegeSelected && Object.keys(errors).length === 0}
                                                            disabled={!collegeSelected}
                                                        >
                                                            Continue
                                                            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.14012 6.99999L16.8599 6.99999M16.8599 6.99999L11.1934 1.33499M16.8599 6.99999L11.1934 12.665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </button>
                                                }

                                            </div>
                                        </form>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimatedPage>

                {
                    verifiedEmailModal && (
                        <div className='fullsection emailCollectionSection'>
                            <div>
                                <div className="bg-white shadow-2xl p-6 rounded-2xl md:mb-0 mb-5 grid grid-cols-12 md:gap-10 gap-0 md:max-w-screen-sm relative m-auto w-full">
                                    <div className="md:col-span-12 col-span-12">
                                        <div className="ShowThankyou">
                                            <div className="text-center">
                                                <img src="/images/Please-verify-email.svg" alt="" className="m-auto max-w-60" />
                                                <p className="text-center text-xl text-regal-blue mb-3 mt-4 max-w-2xl mx-auto font-bold">
                                                    Please verify your email first!
                                                </p>
                                                <p className="text-center text-xl text-regal-blue mb-5 max-w-md mx-auto font-normal">
                                                    {`We have sent you a verification link on ${userEmail}. Didn’t receive it?`}
                                                </p>

                                                <div className="flex justify-center items-center gap-4 mt-4 thankyubtns">

                                                    {
                                                        sendEmailLoading ?
                                                            <button type="button"
                                                                className="inline-flex gap-4 bg-blue-600 text-white md:px-6 lg:px-12 px-8 py-3 text-base rounded-lg shadow-lg items-center shadow-blue-300 hover:bg-regal-blue transition ease-in-out duration-300">
                                                                Email Sending...<span className='loading-state Small'></span>
                                                                
                                                            </button>
                                                            :
                                                            <button type="button"
                                                                onClick={handleClick}
                                                                className="inline-flex gap-4 bg-blue-600 text-white md:px-6 lg:px-12 px-8 py-3 text-base rounded-lg shadow-lg items-center shadow-blue-300 hover:bg-regal-blue transition ease-in-out duration-300">
                                                                Resend Email
                                                                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1.64012 6.99996L17.3599 6.99996M17.3599 6.99996L11.6934 1.33496M17.3599 6.99996L11.6934 12.665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </button>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <span className="close-panel absolute top-4 right-4 cursor-pointer" onClick={handlePopUp} handleVerifyOtpEmail={handleVerifyOtpEmail}>
                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5 1L1.5 11" stroke="#455A64" strokeWidth="2" strokeLinecap="round"></path>
                                            <path d="M11.5 11L1.5 1" stroke="#455A64" strokeWidth="2" strokeLinecap="round"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }
                {verifiedEmailOtpModal && <VerifyEmailPopup email={userEmail} handleClose={handleClose} handleVerifyOtpEmail={handleVerifyOtpEmail}/>}

                {
                    generalQuestionModal && (
                        <div className='fullsection emailCollectionSection'>
                            <div>
                                <div className="bg-white shadow-2xl p-6 rounded-2xl md:mb-0 mb-5 grid grid-cols-12 md:gap-10 gap-0 md:max-w-screen-sm relative m-auto w-full">
                                    <div className="md:col-span-12 col-span-12">
                                        <div className="ShowThankyou">
                                            <div className="text-center">
                                                <img src="/images/General-Questionnaire.svg" alt="" className="m-auto max-w-60" />
                                                <p className="text-center text-xl text-regal-blue mb-3 mt-4 max-w-2xl mx-auto font-bold">
                                                    General Questionnarie!
                                                </p>
                                                <p className="text-center text-lg text-regal-blue mb-5 max-w-sm mx-auto font-normal">
                                                    {`We have a quick general questionnarie that takes just 2 minutes to complete`}
                                                </p>

                                                <div className="flex justify-center items-center gap-4 mt-4 thankyubtns">
                                                    <button type="button" onClick={() => handleGeneralContinue()} className="inline-flex gap-4 bg-blue-600 text-white md:px-6 lg:px-12 px-8 py-3 text-base rounded-lg shadow-lg items-center shadow-blue-300 hover:bg-regal-blue transition ease-in-out duration-300">
                                                        Continue Now
                                                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.64012 6.99996L17.3599 6.99996M17.3599 6.99996L11.6934 1.33496M17.3599 6.99996L11.6934 12.665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </button>
                                                    <span>Or</span>
                                                    <button type="button" onClick={() => handleGeneralSkip()} className="inline-flex gap-4 text-blue-600 md:px-6 lg:px-6 px-3 py-3 text-base rounded-lg items-center shadow-none border border-gray-300 hover:bg-gray-300 transition ease-in-out duration-300">Later</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <span className="close-panel absolute top-4 right-4 cursor-pointer" onClick={() => setGeneralQuestionModal(!generalQuestionModal)}>
                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5 1L1.5 11" stroke="#455A64" strokeWidth="2" strokeLinecap="round"></path>
                                            <path d="M11.5 11L1.5 1" stroke="#455A64" strokeWidth="2" strokeLinecap="round"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    userOnboarding && <OnboardingModalInstitute />
                }

            </>

        )
    }
    navigate("/");


}

export default InstituteAddress