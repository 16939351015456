import React from 'react'
import { useNavigate } from "react-router-dom"

const FinalStepModal = () => {
    const navigate = useNavigate();
    const handleClose = () => {
        const dataToPass = {
            scrollPosition: 5100,
            otherData: 'example'
        };
        navigate('/', { state: dataToPass });
    }
    return (
        <div className='fullsection emailCollectionSection'>
            <div>
                <div class="bg-white shadow-2xl p-6 rounded-2xl md:mb-0 mb-5 grid grid-cols-12 md:gap-10 gap-0 md:max-w-screen-sm relative m-auto w-full">
                    <div class="md:col-span-12 col-span-12">
                        <div class="ShowThankyou">
                            <div class="text-center">
                                <img src="/images/final-stem-img.svg" alt="" class="m-auto max-w-60" />
                                <p class="text-center text-xl text-regal-blue mb-3 mt-4 max-w-2xl mx-auto font-bold">
                                    {"Sit back and Relax! till we verify your details."}
                                </p>
                                <p class="text-center text-lg text-regal-blue mb-5 max-w-sm mx-auto font-norma">
                                    {`You will hear back from us shortly!!`}
                                </p>

                                <div class="flex justify-center items-center gap-4 mt-4 thankyubtns" onClick={handleClose}>

                                    <button type="button" class="inline-flex gap-4 bg-blue-600 text-white md:px-6 lg:px-12 px-8 py-3 text-base rounded-lg shadow-lg items-center shadow-blue-300 hover:bg-regal-blue transition ease-in-out duration-300">
                                        Explore more
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <span class="close-panel absolute top-4 right-4 cursor-pointer" onClick={handleClose}>
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5 1L1.5 11" stroke="#455A64" strokeWidth="2" strokeLinecap="round"></path>
                            <path d="M11.5 11L1.5 1" stroke="#455A64" strokeWidth="2" strokeLinecap="round"></path>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default FinalStepModal