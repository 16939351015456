import React, { useEffect, useState } from 'react'
import InstituteType from '../../Components/InstituteDetails/instituteType'
import { useLocation, useNavigate } from 'react-router-dom';
import { checkEmailRegistered } from '../../Components/common/checkEmailVerify';

const InstituteDetails = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const userEmail = queryParams.get('user-email');
    const [useEmailRegister, setUseEmailRegister] = useState(false)
    const [userOnboarding, setUserOnboarding] = useState(false)
    const [loading, setLoading] = useState(false);
    const [checkEmailRegState, setcheckEmailRegState] = useState(null)

   

    const checkEmailReg = async (email) => {
        setLoading(true);
        const checkEmailReg = await checkEmailRegistered(email);
        setcheckEmailRegState(checkEmailReg)
        if (checkEmailReg.data.is_registered) {
            setUseEmailRegister(true)
        } else {
            setUseEmailRegister(false)
        }
        if (checkEmailReg.data.onboarding_completed) {
            setUserOnboarding(true)
        } else {
            setUserOnboarding(false)
        }
        setLoading(false);
    }

    useEffect(() => {
        if (userEmail) {
            checkEmailReg(userEmail);
        }
    }, [userEmail]);

    if (loading) {
        return <div className='loading-state'>Loading...</div>
    }
    if (useEmailRegister) {
        return ( <InstituteType checkEmailRegState={checkEmailRegState} userOnboarding={userOnboarding}/>)
    }
    navigate("/");
}


export default InstituteDetails