
import React, { useCallback, useEffect, useState } from 'react';
import WelcomeImg from '../../images/home-banner-image.png';
import RoundLeft from '../../images/round-img-left.png';
import RoundRight from '../../images/round-img-right.png';
import watchvideobtn from '../../images/watch-video-btn.svg';
import AnimatedPage from '../../Components/AnimatedWrap/AnimatedPage';
import { motion } from "framer-motion"
import './style.css';
import HomeSlider from '../../Components/HomeCrousel';
import WOW from 'wowjs';
import 'animate.css';
import EmailCollectComp from '../../Components/LoginEmail/emailCollectComp';
import { Link } from "react-router-dom";
import NewRegisterThankuPopup from '../../Components/LoginEmail/newRegisterThankuPopup';
import AllReadyRegisterThankuPopup from '../../Components/LoginEmail/allReadyRegisterThankuPopup';
import AssociateWithNoPopup from '../../Components/LoginEmail/associateWithNoPopup';
import OnboardingModal from '../../Components/LoginEmail/onboardingModal';

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Faq from '../faq';
import { baseUrl } from '../../Components/api/baseUrl';
import { useLocation, useNavigate } from 'react-router-dom';

const HomePage = () => {

    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': 'w1sxc-d35fr-tg5bn-ji2k1',
    };
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state || {};
    const [isModalOpen, setModalOpen] = useState(false);
    const [enrollToggle, setEnrollToggle] = useState(false);
    const [showThankuNewRegistred, setShowThankuNewRegistred] = useState(false);
    const [visitorsThanku, setVisitorsThanku] = useState(false);
    const [showThankuAllreadyRegistered, setShowThankuAllreadyRegistered] = useState(false);
    const [onboardingModal, setOnboardingModal] = useState(false);
    const [userEmail, setUserEmail] = useState(null);
    const [emailAllreadyRegisterd, setEmailAllreadyRegisterd] = useState(false)
    const [loading, setLoading] = useState(false)
    const [subscribe, setSubscribe] = useState(false)

    const EmailSchema = yup.object().shape({
        email: yup.string()
            .email("Please enter valid email")
            .matches(/^[^\s@]+@[^\s@]+\.com$/, "Please enter valid email")
            .required("Email is required")

    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(EmailSchema),
    });


    const onSubmitHandler = async (data) => {
        setLoading(true)
        reset()
        console.log("emailData", data)
        const formData = new FormData();
        formData.append("email", data.email);
        try {
            const response = await baseUrl.post("subscribe", formData, { headers });

            console.log("responseresponse", response.data.code)
            if (response.data.code === "MRCP_1000") {
                setEmailAllreadyRegisterd(false)
                setSubscribe(true)
            } else {
                setSubscribe(false)
                setEmailAllreadyRegisterd(true)
            }
        } catch (error) {
            console.log({ error })
        } finally {
            setLoading(false)
        }

    }

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    useEffect(() => {
        new WOW.WOW().init();
        localStorage.removeItem("rollcallQuestions");
    }, []);

    useEffect(() => {
        if (state.scrollPosition) {
            window.scrollTo({
                top: state.scrollPosition,
                behavior: 'smooth'
            });
        }
        const timeoutId = setTimeout(() => {
            navigate(location.pathname, { replace: true, state: {} });
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [state, navigate, location.pathname]);

    const handleEnrollNow = () => {
        setEnrollToggle(true);
    }

    const handleCloseEmailSection = useCallback(() => {
        setEnrollToggle(false);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    const handleNewThankuSection = (email) => {
        setEnrollToggle(false);
        setShowThankuNewRegistred(true)
        setUserEmail(email)
    }

    const handleNewThankuSectionClose = () => {
        setShowThankuNewRegistred(false)
    }


    const handleAllThankuSection = (email) => {
        setEnrollToggle(false);
        setShowThankuAllreadyRegistered(true)
        setUserEmail(email)
    }

    const handleAllThankuSectionClose = () => {
        setShowThankuAllreadyRegistered(false)
    }

    const handleVisitorsSection = (email) => {
        setEnrollToggle(false);
        setVisitorsThanku(true)
        setUserEmail(email)
    }

    const handleVisitorsClose = () => {
        setVisitorsThanku(false)
    }

    const handleOnboardingModal = (email) => {
        setOnboardingModal(true)
        setEnrollToggle(false);
        setVisitorsThanku(false)
        setVisitorsThanku(false)
        setUserEmail(email)
    }

    const handleOnboardingClose = () => {
        setOnboardingModal(false)

        window.scrollTo({
            top: 5100,
            behavior: 'smooth'
        });
    }



    return (
        <>
            <AnimatedPage>
                <section className='fullsection'>
                    <div className='container mx-auto px-4 home-page pt-5 relative z-20'>
                        <div className="grid grid-cols-12 gap-4 items-center mt-16 mb-16 main-banner">
                            <div className='md:col-span-6 lg:col-span-7 col-span-12 md:order-1 order-2'>
                                <div className='welsome-content md:text-left text-center'>
                                    <div
                                        initial={{ y: 50, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }}
                                        className='md:text-2xl lg:text-4xl xl:text-6xl text-2xl text-regal-blue font-bold text heading-text wow animate__animated animate__fadeInUp' data-wow-duration="1s" data-wow-delay="0.3s"                                >
                                        Lighting-Fast Attendance
                                        <span className='text-blue-600 block'>Accurate, Easy, Reliable.</span>
                                    </div>

                                    <p
                                        initial={{ y: 50, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1, transition: { delay: 0.30, duration: 0.3, ease: 'linear' } }}
                                        className="text-gray-400 text-sm md:text-lg md:my-6 mt-2 mb-2 py-2 max-w-xl md:border-0 md:border-gray-400 md:border-l-4 md:pl-5 banner-paragraph wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                                        Transform your attendance recording with our state-of-the-art system. Seamless integration, powerful analytics, <strong>secure proxy detection</strong> and much more for optimal speed & performance.
                                    </p>

                                    <div
                                        initial={{ y: 50, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1, transition: { delay: 0.45, duration: 0.3, ease: 'linear' } }}
                                        className='flex items-center md:justify-start justify-center mt-5 banner-action-buttons'
                                    >
                                        <button className='inline-block bg-blue-600 text-white md:px-6 lg:px-12 px-5 py-3 text-base rounded-lg shadow-lg shadow-blue-300  hover:bg-regal-blue transition ease-in-out duration-300 wow animate__animated animate__bounceIn' data-wow-duration="1s" data-wow-delay="0.5s"
                                            onClick={() => handleEnrollNow()}
                                        >Get Started
                                        </button>
                                        <button onClick={toggleModal} className='wow animate__animated animate__bounceIn watchvideo' data-wow-duration="1s" data-wow-delay="0.6s"><img src={watchvideobtn} alt='WelcomeImg' /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='columns-1 col-span-12 lg:col-span-5 md:col-span-6 order-1 md:order-2 md:text-right text-center'>
                                <motion.div initial={{ x: 50, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }} className='img-thumbnail md:max-w-max max-w-80 inline-block relative mx-auto'>
                                    <img src={WelcomeImg} alt='WelcomeImg' />
                                    <motion.span className='round-img-left absolute bottom-32 left-10' initial={{ rotate: 180, scale: 0 }} animate={{ rotate: 0, scale: 1 }} transition={{ delay: 0.7, type: "spring", stiffness: 260, damping: 20 }}><img src={RoundLeft} alt='WelcomeImg' className="box" /></motion.span>
                                    <motion.span className='round-img-right absolute bottom-12 right-10' initial={{ rotate: 180, scale: 0 }} animate={{ rotate: 0, scale: 1 }} transition={{ delay: 1, type: "spring", stiffness: 260, damping: 20 }}><img src={RoundRight} alt='WelcomeImg' className="box2" /></motion.span>

                                </motion.div>
                            </div>
                        </div>
                    </div>

                </section>

                {enrollToggle && (
                    <div className='fullsection emailCollectionSection'>
                        <div className={enrollToggle ? 'showEmailCollect' : 'hideEmailCollect'}>
                            <EmailCollectComp
                                handleClose={handleCloseEmailSection}
                                handleNewThankuSection={handleNewThankuSection}
                                handleAllThankuSection={handleAllThankuSection}
                                handleVisitorsSection={handleVisitorsSection}
                                handleOnboardingModal={handleOnboardingModal}

                            />
                        </div>
                    </div>
                )}

                {showThankuNewRegistred && <NewRegisterThankuPopup userEmail={userEmail} handleNewThankuSectionClose={handleNewThankuSectionClose} />}

                {showThankuAllreadyRegistered && <AllReadyRegisterThankuPopup userEmail={userEmail} handleAllThankuSectionClose={handleAllThankuSectionClose} />}

                {visitorsThanku && <AssociateWithNoPopup userEmail={userEmail} handleVisitorsClose={handleVisitorsClose} />}

                {onboardingModal && <OnboardingModal userEmail={userEmail} handleOnboardingClose={handleOnboardingClose} />}



                <section className='fullsection relative'>
                    <div className="our-features">
                        <div className='container text-center'>
                            <h2 className='Features-heading wow animate__animated animate__fadeInDown' data-wow-duration="1.5s" data-wow-delay="0.3s">
                                Lightning-Fast Attendance for Large Classes! <br /> Guaranteed in<span className="orange-text"> 60 Seconds</span>!
                            </h2>
                            <p className='Features-paragraph wow animate__animated animate__fadeInDown max-w-3xl m-auto mb-12' data-wow-duration="1.5s" data-wow-delay="0s"><span className="orange-text">Discover</span> how <span className="text-blue-600">MyRollCall</span> ensures rapid and accurate attendance, even in large classrooms, with bulletproof proxy detection.</p>
                        </div>

                        <div>
                            <div className="container mx-auto px-4 grid grid-cols-12 gap-4 items-center mt-16">
                                <div className='md:col-span-7 col-span-12 md:order-2 order-2 pl-5'>
                                    <motion.div className='welsome-content md:text-left text-center' initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }}>
                                        <h2 className='xl:text-5xl text-3xl font-bold text-regal-blue mb-4 Features-heading wow animate__animated animate__fadeInRight' data-wow-duration="1.5s" data-wow-delay="0.3s"> Adaptive PIN Selection: Mark <span className="orange-text">Attendance</span> in a Blink!</h2>
                                        <p className='text-lg text-gray-600 mb-4 max-w-2xl Features-paragraph wow animate__animated animate__fadeInRight' data-wow-duration="1.5s" data-wow-delay="0.6s">Allows for quick & secure attendance marking, ensuring every student is accounted for <span className="text-blue-600">without disrupting the flow of the class. </span> </p>
                                    </motion.div>
                                </div>
                                <div className='columns-1 col-span-12 md:col-span-5 order-1 md:order-1 md:text-left text-center'>
                                    <motion.div initial={{ x: -50, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }} className='img-thumbnail md:max-w-max inline-block relative'>
                                        <img src='./images/Instant-Pin-Based-Attendance.svg' alt='WelcomeImg' className='md:max-w-full max-w-60 Features-image wow animate__animated animate__fadeInLeft' data-wow-duration="1.5s" data-wow-delay="0.3s" />
                                    </motion.div>
                                </div>
                            </div>
                            <div className='cirsulSHape box2'></div>
                        </div>

                    </div>
                </section>

                <section className='fullsection wave-bg relative'>
                    <div className="RobustProxy">
                        <div className="container mx-auto px-4 grid grid-cols-12 gap-4 items-center">
                            <div className='md:col-span-7 col-span-12 md:order-1 order-2'>
                                <motion.div className='welsome-content md:text-left text-center' initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }}>
                                    <h2 className='xl:text-5xl text-3xl font-bold text-regal-blue mb-4 Features-heading wow animate__animated animate__fadeInLeft' data-wow-duration="1.5s" data-wow-delay="0.3s"> GeoAI Shield: AI-Powered <br /> <span className="orange-text">Proxy</span> Defense</h2>
                                    <p className='text-lg text-gray-600 mb-4 max-w-2xl Features-paragraph wow animate__animated animate__fadeInLeft' data-wow-duration="1.5s" data-wow-delay="0.6s"><span className="text-blue-600">Say goodbye to proxy attendance!</span> <span className="orange-text">MyRollCall</span> uses <span className="text-blue-600">GPS</span>  and <span className="text-blue-600">AI</span>-based measures to ensure that only the right students are marked present</p>
                                </motion.div>
                            </div>
                            <div className='columns-1 col-span-12 md:col-span-5 order-1 md:order-2 md:text-left text-center'>
                                <motion.div initial={{ x: 50, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }} className='img-thumbnail md:max-w-max inline-block relative'>
                                    <img src='./images/Robust-Proxy-Detection.svg' alt='WelcomeImg' className='md:max-w-full max-w-80 Features-image wow animate__animated animate__fadeInRight' data-wow-duration="1.5s" data-wow-delay="0.3s" />
                                </motion.div>
                            </div>

                        </div>
                    </div>
                    <div className="bots-box">
                        <img src='./images/dots-graph.svg' alt='WelcomeImg' />
                    </div>
                </section>


                <section className='fullsection relative'>
                    {/* <div onMouseMove={handleMouseMove}> */}
                    <div>
                        <div className="container mx-auto px-4 grid grid-cols-12 gap-4 items-center mt-16">
                            <div className='md:col-span-7 col-span-12 md:order-2 order-2'>
                                <motion.div className='welsome-content md:text-left text-center' initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }}>
                                    <h2 className='xl:text-5xl text-3xl font-bold text-regal-blue mb-4 Features-heading wow animate__animated animate__fadeInRight' data-wow-duration="1.5s" data-wow-delay="0.3s">Analytics <span className="orange-text">Dashboards</span></h2>
                                    <p className='text-lg text-gray-600 mb-4 max-w-2xl Features-paragraph wow animate__animated animate__fadeInRight' data-wow-duration="1.5s" data-wow-delay="0.6s"><span className="orange-text">MyRollCall</span> provides in-depth analytics dashboards, giving educators <span className="text-blue-600">valuable insights</span> into attendance patterns, student participation, and more.</p>
                                </motion.div>
                            </div>
                            <div className='columns-1 col-span-12 md:col-span-5 order-1 md:order-1 md:text-left text-center'>
                                <motion.div initial={{ x: -50, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }} className='img-thumbnail md:max-w-max inline-block relative'>
                                    <img src='./images/Comprehensive-Analytics-Dashboards.svg' alt='WelcomeImg' className='md:max-w-full max-w-80 Features-image wow animate__animated animate__fadeInLeft' data-wow-duration="1.5s" data-wow-delay="0.3s" />
                                </motion.div>
                            </div>
                        </div>
                        {/* <div className='cirsulSHape box2' style={{ transform: `translate(${boxPosition.x}px, ${boxPosition.y}px)` }}></div> */}
                        <div className='cirsulSHape box2'></div>
                    </div>
                </section>

                <section className='wave-bg fullsection relative'>
                    {/* <div onMouseMove={handleMouseMove}> */}
                    <div>
                        <div className="container mx-auto px-4 grid grid-cols-12 gap-4 items-center">
                            <div className='md:col-span-7 col-span-12 md:order-1 order-2'>
                                <motion.div className='welsome-content md:text-left text-center' initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }}>
                                    <h2 className='xl:text-5xl text-3xl font-bold text-regal-blue mb-4 Features-heading wow animate__animated animate__fadeInLeft' data-wow-duration="1.5s" data-wow-delay="0.3s">Seamless Integration: <span className="orange-text">Custom APIs Made Effortless</span></h2>
                                    <p className='text-lg text-gray-600 mb-4 max-w-2xl Features-paragraph wow animate__animated animate__fadeInLeft' data-wow-duration="1.5s" data-wow-delay="0.6s">
                                        Every institute has unique requirements. MyRollCall offers customizable APIs, allowing <span className="text-blue-600">seamless integration with existing systems</span> and enhancing overall efficiency.
                                    </p>
                                </motion.div>
                            </div>
                            <div className='columns-1 col-span-12 md:col-span-5 order-1 md:order-2 md:text-left text-center'>
                                <motion.div initial={{ x: 50, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }} className='img-thumbnail md:max-w-max inline-block relative'>
                                    <img src='./images/Customizable-APIs.svg' alt='WelcomeImg' className='md:max-w-full max-w-80 Features-image wow animate__animated animate__fadeInRight' data-wow-duration="1.5s" data-wow-delay="0.3s" />
                                </motion.div>
                            </div>
                        </div>
                    </div>
                    <div className="bots-box">
                        <img src='./images/dots-graph.svg' alt='WelcomeImg' />
                    </div>
                </section>

                <section className="DemoSection">
                    <h2>Explore More Cutting-Edge Features</h2>
                    <p>Unlock the full potential of our <span className="orange-text font-bold">advanced attendance recording.</span> Dive into features designed to enhance efficiency, accuracy, and security in your institution.</p>
                    <Link to={"/demo"} className="bookdemo">{"Discover More"}</Link>
                </section>

                <section className='fullsection'>
                    <div className="footer-area">
                        <div className='container mx-auto px-4 md:mt-20 mt-0'>
                            <h2 className='text-center md:text-5xl text-2xl font-bold text-regal-blue mb-10 Features-heading'>What our clients say</h2>
                            <HomeSlider />
                        </div>
                    </div>
                </section>

                <section>
                    <Faq />
                </section>

                <section className='footer fullsection '>
                    <div className="footerbg">
                        <img src='./images/dymend-bg.svg' alt='WelcomeImg' />
                    </div>
                    <div className='footer-bottom'>
                        <div className='container mx-auto px-4 md:mt-10 mt-0'>
                            <div className='StayUpdate max-w-xl mx-auto pt-52 text-center wow animate__animated animate__fadeInUp' data-wow-duration="0.5s" data-wow-delay="0.2s">
                                <h2 className='text-center md:text-5xl text-2xl font-bold text-white mb-4'>Stay Update!</h2>

                                <form onSubmit={handleSubmit(onSubmitHandler)}>
                                    <input
                                        {...register("email")}
                                        className='block w-full bg-white outline-none h-14 my-5 text-center rounded-xl'
                                        type='email' placeholder='Your Email'
                                        onChange={() => setEmailAllreadyRegisterd(false)}
                                    />
                                    <span className="form-error-msg validemail text-sm -mt-4 mb-4 block">{errors?.email?.message}</span>
                                    {
                                        emailAllreadyRegisterd && (
                                            <span className="form-error-msg already text-sm -mt-4 mb-4 block">{"You are already subscribed to our newsletter "}</span>
                                        )
                                    }
                                    {
                                        subscribe && (
                                            <span className="form-error-msg thankyou text-sm -mt-4 mb-4 block">Thank you for subscribing to our newsletter!</span>
                                        )
                                    }

                                    {
                                        loading ?
                                            <button type='button' className='subscribe bg-gradient-to-r from-blue-500 to-cyan-100 hover:to-blue-500 hover:from-cyan-100 text-white px-16 py-4 tracking-wider rounded-lg shadow-2xl'>SUBSCRIBE <span className='loading-state Small'></span></button>

                                            :

                                            <button type='submit' className='subscribe bg-gradient-to-r from-blue-500 to-cyan-100 hover:to-blue-500 hover:from-cyan-100 text-white px-16 py-4 tracking-wider rounded-lg shadow-2xl'>SUBSCRIBE</button>

                                    }
                                </form>
                            </div>
                            <div className='bottom-footer flex flex-col lg:flex-row lg:mt-20 mt-10 mb-6 lg:px-40 p-0 items-center gap-2 justify-between'>
                                <div className='text-white'>
                                    {" © 2024 SkyoIndia All Rights Reserved"}
                                </div>
                                <div className='text-white'>
                                    {"+91-9888302322 | info@skyoIndia.com"}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </AnimatedPage>

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50 p-4">
                    <div className="modalanimation bg-white p-5 rounded-lg relative w-full max-w-6xl">
                        <button onClick={toggleModal} className="absolute -right-4 text-lg font-bold leading-6 -top-4 rounded-full w-9 h-9 bg-red-500 flex justify-center items-center">
                            <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 15L8 8M8 8L15 1M8 8L15 15M8 8L1 1" stroke="#fff" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                        <div className='video'>
                            <iframe className='w-full h-screen modalvideo' src="https://www.youtube.com/embed/518YEGXE7Q8" title="Students walking through a college | Free Stock Footage - No Copyright" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};

export default HomePage;
