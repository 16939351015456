import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const GoogleLogin = ({ handleEmail, googleLoading }) => {

    const [loading, setLoading] = useState(false);
    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
                    { headers: { Authorization: `Bearer ${tokenResponse.access_token}`, Accept: 'application/json' } }
                );
                console.log(response.data);
                handleEmail(response.data.email)
            } catch (error) {
                alert("Error fetching user information")
                console.error('Error fetching user information', error);
            } finally {
                setLoading(false);
            }
        },
        onError: error => console.error('Login Failed:', error)
    });
    return (
        <div className="googleLogin">

            {
                googleLoading ? <div className='loader-wrap'><div className='google-login-loader'></div></div>
                    :
                    <>
                        {/* <span>or</span> */}
                        <div className="loginwithGoogle" onClick={() => login()}>
                            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.87566 13.7949L4.10988 16.6537L1.31093 16.7129C0.474461 15.1614 0 13.3863 0 11.5C0 9.67593 0.443609 7.95581 1.22994 6.4412L3.72238 6.89805L4.81396 9.37494C4.5855 10.041 4.46097 10.756 4.46097 11.5C4.46106 12.3075 4.60732 13.0811 4.87566 13.7949Z" fill="#FBBC05"/>
                                <path d="M21.8078 9.44538C21.9341 10.1108 22 10.798 22 11.5003C22 12.2878 21.9172 13.056 21.7594 13.797C21.2239 16.3186 19.8248 18.5204 17.8865 20.0785L14.7473 19.9178L14.3031 17.1448C15.5892 16.3905 16.5943 15.2101 17.1238 13.797H11.2418V9.44538H21.8078Z" fill="#4285F4"/>
                                <path d="M17.8859 20.0776L17.8865 20.0785C16.0014 21.5937 13.6067 22.5 11 22.5C6.81091 22.5 3.16886 20.1585 1.31093 16.7129L4.87566 13.7949C5.80461 16.2741 8.19618 18.039 11 18.039C12.2051 18.039 13.3342 17.7135 14.3031 17.1448L17.8859 20.0776Z" fill="#34A853"/>
                                <path d="M18.0213 3.03241L14.4577 5.94981C13.4551 5.32307 12.2698 4.96102 11 4.96102C8.13274 4.96102 5.69637 6.80682 4.81396 9.37494L1.22994 6.4412C3.06066 2.91154 6.74867 0.5 11 0.5C13.669 0.5 16.1162 1.45073 18.0213 3.03241Z" fill="#EA4335"/>
                            </svg>
                            Continue with Google <strong>(Recommended)</strong>
                        </div>
                    </>
            }
        </div>
    )
}
export default GoogleLogin