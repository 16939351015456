import React, { useRef, useState } from 'react'
import { baseUrl } from '../api/baseUrl';

const VerifyEmailPopup = ({ email, handleClose, handleVerifyOtpEmail }) => {
    const [otpShow, setOtpShow] = useState(false)
    const [otp, setOtp] = useState(Array(6).fill(''));
    const inputRefs = useRef(Array(6).fill(null));
    const [otpLoading, setOtpLoading] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [apiOtpError, setApiOtpError] = useState(null);
    const [resendEmail, setResendEmail] = useState(false);


    const toggleModal = () => {
        setOtpShow(!otpShow);
    };

    const handleInputChange = (value, index) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleverifyOtp = async () => {
        setOtpLoading(true)
        let enteredOtp = otp.join('');
        if (enteredOtp === "") {
            setOtpError(true)
            setOtpLoading(false)
        } else if (enteredOtp.length < 6) {
            setOtpError(true)
            setOtpLoading(false)
        } else {

            try {
                const myHeaders = {
                    "Content-Type": "application/json",
                    "x-api-key": "w1sxc-d35fr-tg5bn-ji2k1"
                };

                const data = {
                    "email_or_phone_no": email,
                    "otp": enteredOtp
                };

                const json = {
                    email: email,
                    is_email_verified: true
                }

                const res = await baseUrl.post('auth/verify-otp', data, {
                    headers: myHeaders
                });
                console.log("vaerify res", res.data)
                if (res.data.code === "MRCP_030") {   // opt expire
                    setOtp(Array(6).fill(''))
                    setApiOtpError(res.data.error)
                    setResendEmail(true)
                } else if (res.data.code === "MRCP_029") { // opt expire
                    setOtp(Array(6).fill(''))
                    setApiOtpError(res.data.error)
                } else if (res.data.code === "MRCP_1000") {
                    const response = await baseUrl.post("get-started", json, {
                        headers: myHeaders
                    });
                    if (response) {
                        handleVerifyOtpEmail()
                    }
                    console.log("response", response)
                } else {
                    alert("something went Wrong! please try after some time")
                }

                setOtpLoading(false)
            } catch (err) {
                console.log("err", err)
                alert("something went Wrong! please try after some time")
                setOtpLoading(false)
            }

        }
    }

    const handleResendEmail = async () => {
        setApiOtpError(null)
        const myHeaders = {
            "Content-Type": "application/json",
            "x-api-key": "w1sxc-d35fr-tg5bn-ji2k1"
        };

        const data = {
            "email_or_phone_no": email
        };
        const res = await baseUrl.post(`auth/send-otp`, data, {
            headers: myHeaders
        });
        if (res) {
            setResendEmail(false)
        }
        console.log("resresresresres", res)
    }
    const handlePaste = (e) => {
        const pastedValue = e.clipboardData.getData('text').trim();
        if (/^\d{6}$/.test(pastedValue)) {
            const newOtp = pastedValue.split('');
            setOtp(newOtp);
            newOtp.forEach((digit, index) => {
                inputRefs.current[index].value = digit;
            });
            inputRefs.current[5].focus();
        }
        e.preventDefault();
    };
    return (
        <div className='fullsection emailCollectionSection'>
            <div>
                <div className="bg-white shadow-2xl p-6 rounded-2xl md:mb-0 mb-5 grid grid-cols-12 md:gap-10 gap-0 md:max-w-screen-sm relative m-auto w-full">
                    <div className="md:col-span-12 col-span-12">
                        <div className="ShowThankyou">
                            <div className="text-center">
                                <img src="/images/Otp.svg" alt="" className="m-auto max-w-60" />
                                <p className="text-center text-xl text-regal-blue mb-3 mt-4 max-w-2xl mx-auto font-bold">
                                    {` Enter the verification code sent to ${email}`}
                                </p>
                                {/* <p className="text-center text-xl text-regal-blue mb-5 max-w-md mx-auto font-normal">
                                    {`We have sent you a verification link on ${email}. Didn’t receive it?`}
                                </p> */}

                                <div className="flex justify-center items-center gap-4 mt-4 thankyubtns">
                                    {/* <button type="button" className="inline-flex gap-4 bg-blue-600 text-white md:px-6 lg:px-12 px-8 py-3 text-base rounded-lg shadow-lg items-center shadow-blue-300 hover:bg-regal-blue transition ease-in-out duration-300">
                                        Resend Email
                                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.64012 6.99996L17.3599 6.99996M17.3599 6.99996L11.6934 1.33496M17.3599 6.99996L11.6934 12.665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </button> */}

                                    <form>
                                        <div className="space-x-2 flex justify-center pb-3 otpInput">
                                            {otp.map((digit, index) => (
                                                <input
                                                    key={index}
                                                    type="text"
                                                    maxLength="1"
                                                    className="w-12 h-12 text-2xl text-center border-b-2 border-gray-300  focus:outline-none"
                                                    value={digit}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) { // Ensure only numeric input
                                                            handleInputChange(value, index);
                                                            setOtpError(false);
                                                            setApiOtpError(null)
                                                        }
                                                    }}
                                                    onKeyDown={e => handleKeyDown(e, index)}
                                                    ref={el => inputRefs.current[index] = el}
                                                    autoComplete="off"
                                                    // onPaste={index === 0 ? handlePaste : null} // Add paste event listener to the first input
                                                    onPaste={handlePaste} // Add paste event listener to the first input
                                                />
                                            ))}
                                        </div>
                                        <div className='otp-error'>
                                            {
                                                otpError && <p className="form-error-msg text-red-600 text-sm text-center mb-4 block">{"Please enter valid otp"}</p>
                                            }
                                            {
                                                apiOtpError && <p className="form-error-msg text-red-600 text-sm text-center mb-4 block">{apiOtpError}</p>
                                            }
                                        </div>
                                        {
                                            resendEmail ?
                                                <div className='text-center'>
                                                    <button onClick={handleResendEmail} type="button" className="block mx-auto px-6 py-2 text-white rounded-lg bg-blue-600 hover:bg-blue-700 focus:outline-none mt-4">Resend Email</button>
                                                </div>
                                                :
                                                otpLoading ?
                                                    <div className='text-center'>
                                                        <button type="button" className="flex gap-3 mx-auto px-6 py-2 text-white rounded-lg bg-blue-600 hover:bg-blue-700 focus:outline-none mt-4">Verifying <span className='loading-state Small'></span></button>
                                                    </div>
                                                    :
                                                    <div className='text-center'>
                                                        <button onClick={handleverifyOtp} type="button" className="block mx-auto px-6 py-2 text-white rounded-lg bg-blue-600 hover:bg-blue-700 focus:outline-none mt-4">Verify</button>
                                                    </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <span className="close-panel absolute top-4 right-4 cursor-pointer" onClick={handleClose}>
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5 1L1.5 11" stroke="#455A64" strokeWidth="2" strokeLinecap="round"></path>
                            <path d="M11.5 11L1.5 1" stroke="#455A64" strokeWidth="2" strokeLinecap="round"></path>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmailPopup