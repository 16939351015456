import React, { useMemo, useRef, useState } from 'react'
import ArrangingFiles from '../../images/ArrangingFiles.svg';
import GoogleLogin from './GoogleLogin';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { checkEmailRegistered, checkMobileRegistered } from '../common/checkEmailVerify';
import { baseUrl } from '../api/baseUrl';
import { Link } from "react-router-dom";

const EmailCollectComp = ({ setEnrollToggle, handleClose, handleNewThankuSection, handleAllThankuSection, handleVisitorsSection, handleOnboardingModal }) => {

    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': 'w1sxc-d35fr-tg5bn-ji2k1',
    };
    const phoneRegExp = /^[6-9]\d{0,9}$/;

    const [instituteAssociate, setInstituteAssociate] = useState(null);
    const [instituteAssociateShow, setInstituteAssociateShow] = useState(true);
    const [emailSections, setEmailSections] = useState(false)
    const [emailCheckBox, setEmailCheckbox] = useState(false)

    const [userEmail, setUserEmail] = useState(null)
    const [emailAllreadyRegister, setEmailAllreadyRegister] = useState(false)

    const [otpShow, setOtpShow] = useState(false)
    const [otp, setOtp] = useState(Array(6).fill(''));
    const inputRefs = useRef(Array(6).fill(null));

    const [phoneNumber, setPhoneNumber] = useState(null)

    const [mobile, setMobile] = useState(null)
    const [mobileError, setMobileError] = useState(null);
    const [otpError, setOtpError] = useState(false);

    const [loading, setLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);

    const [checkEmailRegState, setCheckEmailRegState] = useState(null)

    const [mobileAllreadyExist, setMobileAllreadyExist] = useState(false)
    const [googleLoading, setGoogleLoading] = useState(false)

    const [mobileNotAssocited, setMobileNotAssocited] = useState(false)
    const [apiOtpError, setApiOtpError] = useState(null);
    const [resendOtp, setResendOtp] = useState(false);
    const [resendOtpLoading, setResendOtpLoading] = useState(false)



    const [associateArr, setassociateArr] = useState([
        {
            id: 1,
            name: "associate",
            value: "yes",
            label: "Yes",
            icon: `✔`,
            checked: false

        },
        {
            id: 2,
            name: "associate",
            value: "no",
            label: "No",
            icon: "✖",
            checked: false

        }
    ])

    const selectAssociateArr = useMemo(() => {
        return associateArr.find(item => item.checked)
    }, [associateArr])


    const EmailSchema = yup.object().shape({
        email: yup.string()
            .email("Please enter a valid email")
            // .matches(/^[^\s@]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/, "Please enter a valid email")
            .matches(/^[^\s@]+@[a-zA-Z]+\.com$/, "Please enter a valid email")
            .required("Email is required")
    });

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(EmailSchema),
        mode: 'onChange',
    });

    const onSubmitHandler = async (data) => {
        setMobileNotAssocited(false)
        const email = data.email
        setUserEmail(email)
        setLoading(true)
        const checkEmailReg = await checkEmailRegistered(email);
        setCheckEmailRegState(checkEmailReg)
        // setLoading(false)
        if (checkEmailReg.status) {

            if (mobile) {
                if (!phoneRegExp.test(mobile)) {
                    setMobileError('Phone number is not valid');
                    setLoading(false)
                } else {
                    if (!mobileError) {
                        if (checkEmailReg.data.onboarding_completed === false) {
                            if (checkEmailReg.data.phone_number === null) {
                                const checkMobileReg = await checkMobileRegistered(mobile)

                                console.log("checkMobileRegcheckMobileReg", checkMobileReg)

                                if (checkMobileReg.status) {
                                    setLoading(false)
                                    setMobileAllreadyExist(true)
                                } else {
                                    setMobileError(null);
                                    const values = mobile.toString().slice(-4);
                                    setPhoneNumber(values)
                                    setInstituteAssociateShow(false)
                                    setEmailSections(false)
                                    setOtpShow(true)
                                    setEmailAllreadyRegister(true)
                                    setLoading(false)
                                }
                            } else {
                                if (checkEmailReg.data.phone_number === mobile) {
                                    const json = {
                                        email: email,
                                        is_user_associated_with_institute: instituteAssociate
                                    }

                                    const response = await baseUrl.post("get-started", json, { headers })
                                    setMobileError(null);
                                    setInstituteAssociateShow(false)
                                    setEmailSections(false)
                                    setEmailAllreadyRegister(true)

                                    if (response.data.success) {
                                        if (instituteAssociate === "yes") {
                                            handleAllThankuSection(email)
                                            setLoading(false)
                                        } else {
                                            handleVisitorsSection(email)
                                            setLoading(false)

                                        }
                                    }

                                } else {
                                    setMobileNotAssocited(true)
                                    setLoading(false)
                                }
                            }


                        } else {
                            handleOnboardingModal(email)
                        }
                    }




                }
            } else {
                if (checkEmailReg.data.onboarding_completed === false) {
                    setLoading(true)
                    const json = {
                        email: email,
                        is_user_associated_with_institute: instituteAssociate
                    }
                    const response = await baseUrl.post("get-started", json, { headers })
                    console.log("response", response);

                    if (response.data) {

                        setInstituteAssociateShow(false)
                        setEmailSections(false)
                        if (instituteAssociate === "yes") {
                            handleAllThankuSection(email)
                        } else {
                            handleVisitorsSection(email)
                        }
                        setLoading(false);
                    }

                } else {
                    // alert("Onboarding already done")
                    handleOnboardingModal(email)
                }
            }


        } else {
            if (mobile) {
                if (!phoneRegExp.test(mobile)) {
                    setMobileError('Phone number is not valid');
                    setLoading(false)
                } else {
                    setLoading(true)
                    const checkMobileReg = await checkMobileRegistered(mobile)
                    console.log("checkMobileReg", checkMobileReg);
                    setLoading(false)

                    if (checkMobileReg.status) {
                        setMobileAllreadyExist(true)
                    } else {
                        setLoading(true)
                        try {
                            setMobileError(null);
                            const values = mobile.toString().slice(-4);
                            setPhoneNumber(values)
                            const myHeaders = {
                                "Content-Type": "application/json",
                                "x-api-key": "w1sxc-d35fr-tg5bn-ji2k1"
                            };

                            const data = {
                                "email_or_phone_no": mobile
                            };
                            const res = await baseUrl.post(`auth/send-otp`, data, {
                                headers: myHeaders
                            });
                            if (res.data) {
                                setLoading(false)
                                setOtpShow(true)
                                setInstituteAssociateShow(false)
                                setEmailSections(false)
                            }
                            console.log("resresresresres", res)
                        } catch (error) {
                            setLoading(false)
                            console.log("error", error)
                            alert("Server serror: please try after sometime")
                        }



                    }


                }
            } else {
                const json = {
                    email: email,
                    is_user_associated_with_institute: instituteAssociate
                }

                try {
                    setLoading(true)
                    const response = await baseUrl.post("get-started", json, { headers })
                    console.log("response", response);
                    if (response.data.success) {
                        setLoading(false)
                        setInstituteAssociateShow(false)
                        setEmailSections(false)
                        if (instituteAssociate === "yes") {
                            handleNewThankuSection(email)
                        } else {
                            handleVisitorsSection(email)
                        }
                    }

                } catch (err) {
                    setLoading(true)
                }
            }

        }
    }


    const handleGoogleEmail = async (email) => {
        setGoogleLoading(true)
        const checkEmailReg = await checkEmailRegistered(email);

        if (checkEmailReg.status) {
            if (checkEmailReg.data.onboarding_completed) {
                // alert("Onboarding allready completed.")
                handleOnboardingModal(email)
            } else {

                // handleAllThankuSection(email)
                if (instituteAssociate === "no") {
                    handleVisitorsSection(email)
                } else {
                    handleAllThankuSection(email)
                    // navigate(`institute-details?user-email=${email}`)
                }
            }
            setGoogleLoading(false)

        } else {
            const json = {
                email: email,
                is_email_verified: true,
                is_user_associated_with_institute: instituteAssociate
            }
            try {
                const response = await baseUrl.post("get-started", json, { headers })
                console.log("response", response);
                if (response.data.success) {
                    setGoogleLoading(true)
                    if (instituteAssociate === "no") {
                        handleVisitorsSection(email)
                    } else {
                        handleNewThankuSection(email)
                        // navigate(`institute-details?user-email=${email}`)
                    }
                    // handleNewThankuSection(email)

                }
            } catch (err) {
                setGoogleLoading(false)
                alert("Please wait server not responding, please try after sometime")
            }

            console.log("varified Email true", json)
        }



        // setEmailvarified(true)
    }

    const handleInstituteAssociate = (id, value) => {
        setInstituteAssociate(value);
        setassociateArr(prev => {
            return prev.map(item => {
                if (item.id === id) {
                    item.checked = true
                }
                return item
            })
        })
        setInstituteAssociateShow(false)
        setEmailSections(true)
    }

    const handleEmailcheckBox = (e) => {
        const { checked } = e.target;
        setEmailCheckbox(checked)
    }

    const handleMobileChange = (e) => {
        setMobileNotAssocited(false)
        setMobileAllreadyExist(false)
        const value = e.target.value;
        // Allow empty value for backspace scenario


        if (/^\d{0,10}$/.test(value)) {
            setMobile(value);

            // Check if the first digit is between 6 and 9
            if (value.length > 0 && (value[0] < '6' || value[0] > '9')) {
                setMobileError('Phone number is not valid');
            } else {
                setMobileError(null);
            }
        } else {
            setMobileError('Phone number is not valid');
        }

    }

    const toggleModal = () => {
        // setInstituteAssociateShow(true)
        setEmailSections(true)
        setOtpShow(!otpShow);
        setApiOtpError(null)
    };

    const handleInputChange = (value, index) => {
        setApiOtpError(null)
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleverifyOtp = async () => {
        setOtpLoading(true)
        let enteredOtp = otp.join('');
        if (enteredOtp === "") {
            setOtpError(true)
            setOtpLoading(false)
        } else if (enteredOtp.length < 6) {
            setOtpError(true)
            setOtpLoading(false)
        } else {
            if (emailAllreadyRegister) {
                const json = {
                    email: userEmail,
                    is_email_verified: checkEmailRegState.data.is_email_verified,
                    phone_number: mobile,
                    is_phone_number_verified: true,
                    onboarding_completed: false,
                    is_user_associated_with_institute: instituteAssociate === "yes" ? true : false
                }
                if (checkEmailRegState.data.onboarding_completed) {
                    // alert("Onboarding allready completed")
                    handleOnboardingModal(userEmail)
                } else {

                    try {
                        const response = await baseUrl.post("get-started", json, { headers })
                        console.log("response", response);
                        if (response.data.success) {
                            console.log("api json with mobile number", json)
                            setOtpLoading(false);
                            setOtpShow(false);
                            if (instituteAssociate === "yes") {
                                handleAllThankuSection(userEmail)
                            } else {
                                handleVisitorsSection(userEmail)
                            }

                        }
                    } catch (err) {
                        console.log(err)
                    }

                }
            } else {

                const data = {
                    "email_or_phone_no": mobile,
                    "otp": enteredOtp
                };
                const myHeaders = {
                    "Content-Type": "application/json",
                    "x-api-key": "w1sxc-d35fr-tg5bn-ji2k1"
                };



                const json = {
                    email: userEmail,
                    phone_number: mobile,
                    is_phone_number_verified: true,
                    is_user_associated_with_institute: instituteAssociate === "yes" ? true : false
                }
                try {
                    const verifyRes = await baseUrl.post("auth/verify-otp", data, {
                        headers: myHeaders
                    });
                    if (verifyRes.data.code === "MRCP_1000") {
                        const response = await baseUrl.post("get-started", json, { headers })
                        console.log("response", response);
                        if (response.data.success) {
                            setOtpLoading(false)
                            setOtpShow(false)
                            if (instituteAssociate === "no") {
                                handleVisitorsSection(userEmail)
                            } else {
                                handleNewThankuSection(userEmail)
                            }
                        }
                    } else if (verifyRes.data.code === "MRCP_030") {
                        setApiOtpError(verifyRes.data.error);
                        setOtp(Array(6).fill(''))
                        setOtpLoading(false)
                        setResendOtp(true)
                    } else if (verifyRes.data.code === "MRCP_029") {
                        setApiOtpError("Invalid OTP");
                        setOtp(Array(6).fill(''))
                        setOtpLoading(false)
                    } else {
                        setOtpLoading(false)
                        alert("something went Wrong! please try after some time")
                    }

                } catch (err) {
                    console.log(err)
                }


            }
        }
    }

    const handlResendOtp = async () => {
        setResendOtpLoading(true)
        setApiOtpError(null)

        try {
            const myHeaders = {
                "Content-Type": "application/json",
                "x-api-key": "w1sxc-d35fr-tg5bn-ji2k1"
            };

            const data = {
                "email_or_phone_no": mobile
            };
            const res = await baseUrl.post(`auth/send-otp`, data, {
                headers: myHeaders
            });

            if (res) {
                setResendOtp(false)
            }
        } catch (error) {
            console.log("error", error)
            alert("Server serror: please try after sometime")
        } finally {
            setResendOtpLoading(false)
        }
    }

    const handlePaste = (e) => {
        const pastedValue = e.clipboardData.getData('text').trim();
        if (/^\d{6}$/.test(pastedValue)) {
            const newOtp = pastedValue.split('');
            setOtp(newOtp);
            newOtp.forEach((digit, index) => {
                inputRefs.current[index].value = digit;
            });
            inputRefs.current[5].focus();
        }
        e.preventDefault();
    };

    return (
        <div className="container mx-auto px-4 WelcomeEmail mt-10 relative">
            <div className="bg-white shadow-2xl p-6 rounded-2xl md:mb-0 mb-5 grid grid-cols-12 md:gap-10 gap-0 md:max-w-screen-lg relative m-auto w-full">

                <div className="md:col-span-7 col-span-12">
                    {
                        !otpShow && (
                            <>
                                <h2 className="welcomeText">Welcome! Let's Get Started</h2>
                                <p className="textarea">Please enter your details to begin with.</p>
                            </>
                        )
                    }


                    {/* Associte section */}
                    {
                        instituteAssociateShow && (
                            <div className="associate-section areyouassosiated-box">
                                <h2 className="">Are you associated with any institute?</h2>
                                <div className="yesNoTabs">

                                    {
                                        associateArr.map((item) => (
                                            <div className="tabItem" key={item.id}>
                                                <div className="Icon">{item.icon}</div>
                                                <input id="default-radio-1" checked={item.checked} type="radio" onChange={() => handleInstituteAssociate(item.id, item.value)} value={item.value} name='associate' />
                                                <label htmlFor="default-radio-1">{item.label}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    }

                    {

                        emailSections && (
                            <form onSubmit={handleSubmit(onSubmitHandler)} className="text-center md:text-left">

                                <div className="associate-section-with-email">
                                    <div className='selectAssociateArr'>Are you associated with any institute? <span> <i>{selectAssociateArr?.icon}</i> <label>{selectAssociateArr?.label}</label></span></div>

                                    <div className='google-login'>
                                        <GoogleOAuthProvider clientId="1039931416672-i8l36uo3lsi3cjed8qfoustlir0d4rh4.apps.googleusercontent.com">
                                            <GoogleLogin handleEmail={handleGoogleEmail} googleLoading={googleLoading} />
                                        </GoogleOAuthProvider>
                                    </div>
                                    <div className='devider'>
                                        <span>OR</span>
                                    </div>
                                    <div className='email-check-box'>
                                        <input id="continue" type='checkbox' onChange={handleEmailcheckBox} checked={emailCheckBox} />
                                        <label htmlFor="continue">Continue with email</label>
                                    </div>


                                    <div className="terms">
                                        By accessing or using our website you agree to comply
                                        with and be bound by our <Link to="/privacy-policy"><strong>Privacy policy</strong></Link>.
                                        If you do not agree with these Terms, please do not use our Service.
                                    </div>
                                    {
                                        emailCheckBox && (
                                            <>
                                                <div className="relative mb-5 mt-5 md:w-96 w-full custom-input">

                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <input
                                                                type="email"
                                                                id="floating_outlined"
                                                                {...field}
                                                                className="block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                placeholder="Email"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    />
                                                    {/* <input
                                                        type="email"
                                                        id="floating_outlined"
                                                        {...register("email")}
                                                        className="block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                        placeholder="Email"

                                                        autoComplete="off"
                                                    /> */}

                                                    <label
                                                        htmlFor="floating_outlined"
                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 pointer-events-none"
                                                    >
                                                        Email
                                                    </label>
                                                </div>
                                                <span className="form-error-msg text-red-600 text-sm -mt-4 mb-4 block">{errors?.email?.message}</span>

                                                <div className="relative mb-5 mt-7 md:w-96 w-full custom-input">
                                                    <input
                                                        type="text"
                                                        id="floating_outlined"
                                                        value={mobile || ""}
                                                        // onChange={(e) => setMobile(e.target.value)}
                                                        onChange={handleMobileChange}
                                                        className="block px-2 py-3.5 w-full text-sm text-gray-900 bg-white rounded-lg border-solid border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                        placeholder="Mobile"
                                                        autoComplete="off"
                                                        maxLength="10"
                                                    />


                                                    <label
                                                        htmlFor="floating_outlined"
                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 pointer-events-none"
                                                    >
                                                        Mobile (Optional)
                                                    </label>
                                                </div>

                                                {
                                                    mobileNotAssocited && <span className="form-error-msg text-red-600 text-sm -mt-4 mb-4 block">{"Mobile number not associated with email"}</span>
                                                }
                                                {
                                                    mobileError && <span className="form-error-msg text-red-600 text-sm -mt-4 mb-4 block">{mobileError}</span>
                                                }
                                                {
                                                    mobileAllreadyExist && <span className="form-error-msg text-red-600 text-sm -mt-4 mb-4 block">{"Mobile number already exist"}</span>
                                                }



                                                {
                                                    loading ?
                                                        <div className='continue'>
                                                            <button
                                                                type="button"
                                                                className={` mt-4 inline-flex gap-4 bg-blue-600 text-white md:px-6 lg:px-12 px-8 py-3 text-base rounded-lg shadow-lg items-center shadow-blue-300  hover:bg-regal-blue transition ease-in-out duration-300`}
                                                            >Continue <span className='loading-state Small'></span>
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className='continue'>
                                                            <button
                                                                type="submit"
                                                                className={` mt-4 inline-flex gap-4 bg-blue-600 text-white md:px-6 lg:px-12 px-8 py-3 text-base rounded-lg shadow-lg items-center shadow-blue-300  hover:bg-regal-blue transition ease-in-out duration-300`}
                                                            >Continue
                                                                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1.64012 6.99996L17.3599 6.99996M17.3599 6.99996L11.6934 1.33496M17.3599 6.99996L11.6934 12.665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </button>
                                                        </div>

                                                }

                                            </>


                                        )
                                    }




                                </div>
                            </form>

                        )
                    }

                    {
                        otpShow && (
                            <div className="show-otp-section">
                                <div className="bg-white rounded-lg w-full max-w-md">
                                    <button onClick={toggleModal} className="absolute left-3 text-lg font-bold leading-6 top-3 rounded-full">
                                        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21 8.00001H1M1 8.00001L7.66666 1.33334M1 8.00001L7.66666 14.6667" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                    <div className='otp-info text-center'>
                                        {/* <img className='mx-auto mb-4 mt-7' src={"/images/otp-img.svg"} alt='' /> */}
                                        <img src="/images/Otp.svg" alt="" className="m-auto max-w-44 mb-4 mt-7" />
                                        <p className='text-center text-md md:text-lg'>Enter the verification code<br />sent to +91******{phoneNumber}</p>
                                    </div>
                                    <form>
                                        <div className="space-x-2 flex justify-center pb-3 otpInput">
                                            {otp.map((digit, index) => (
                                                <input
                                                    key={index}
                                                    type="text"
                                                    maxLength="1"
                                                    className="w-12 h-12 text-2xl text-center border-b-2 border-gray-300  focus:outline-none"
                                                    value={digit}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) { // Ensure only numeric input
                                                            handleInputChange(value, index);
                                                            setOtpError(false);
                                                        }
                                                    }}
                                                    onKeyDown={e => handleKeyDown(e, index)}
                                                    ref={el => inputRefs.current[index] = el}
                                                    autoComplete="off"
                                                    onPaste={handlePaste}
                                                />
                                            ))}
                                        </div>
                                        <div className='otp-error'>
                                            {
                                                otpError && <p className="form-error-msg text-red-600 text-sm text-center mb-4 block">{"Please enter valid otp"}</p>
                                            }

                                            {
                                                apiOtpError && <p className="form-error-msg text-red-600 text-sm text-center mb-4 block">{apiOtpError}</p>
                                            }
                                        </div>
                                        {
                                            resendOtp ?
                                                resendOtpLoading ?
                                                    <div className='text-center'>
                                                        <button type="button" className="block mx-auto px-6 py-2 text-white rounded-lg bg-blue-600 hover:bg-blue-700 focus:outline-none mt-4"><span className='loading-state Small'></span></button>
                                                    </div>
                                                    :
                                                    <div className='text-center'>
                                                        <button onClick={handlResendOtp} type="button" className="block mx-auto px-6 py-2 text-white rounded-lg bg-blue-600 hover:bg-blue-700 focus:outline-none mt-4">Resend Otp</button>
                                                    </div>
                                                :
                                                otpLoading ?
                                                    <div className='text-center'>
                                                        <button type="button" className="flex gap-3 mx-auto px-6 py-2 text-white rounded-lg bg-blue-600 hover:bg-blue-700 focus:outline-none mt-4">Verifying <span className='loading-state Small'></span></button>
                                                    </div>
                                                    :
                                                    <div className='text-center'>
                                                        <button onClick={handleverifyOtp} type="button" className="block mx-auto px-6 py-2 text-white rounded-lg bg-blue-600 hover:bg-blue-700 focus:outline-none mt-4">Verify</button>
                                                    </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className="md:col-span-5 col-span-12 text-center md:m-0 mt-6">
                    <img src={ArrangingFiles} alt="" className="m-auto md:block hidden" />
                </div>
                <span className="close-panel absolute top-4 right-4 cursor-pointer" onClick={handleClose}>
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 1L1.5 11" stroke="#455A64" strokeWidth="2" strokeLinecap="round" />
                        <path d="M11.5 11L1.5 1" stroke="#455A64" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                </span>

            </div>
        </div>
    )
}

export default EmailCollectComp