export const indiaColleges = [
    "Abhilashi University",
    "Acharya N.G. Ranga Agricultural University",
    "Acharya Nagarjuna University",
    "Acharya Narendra Deva University of Agriculture & Technology",
    "Adamas University",
    "Adesh University",
    "Adikavi Nannaya University",
    "Ahmedabad University",
    "Ajeenkya D.Y. Patil University",
    "Akal University",
    "AKS University",
    "Alagappa University",
    "Alakh Prakash Goyal Shimla University",
    "Al-Falah University",
    "Aliah University",
    "Aligarh Muslim University",
    "All India Institute of Medical Sciences Bhopal",
    "All India Institute of Medical Sciences Bhubaneswar",
    "All India Institute of Medical Sciences Delhi",
    "All India Institute of Medical Sciences Jodhpur",
    "All India Institute of Medical Sciences Patna",
    "All India Institute of Medical Sciences Raipur",
    "All India Institute of Medical Sciences Rishikesh",
    "Alliance University",
    "Ambedkar University Delhi",
    "Amity University",
    "Amrita Vishwa Vidyapeetham",
    "Anand Agricultural University",
    "Anant National University",
    "Andhra University",
    "Anna University",
    "Annamalai University",
    "Apeejay Stya University",
    "Apex Professional University",
    "Apex University",
    "APJ Abdul Kalam Technological University",
    "Arka Jain University",
    "Arni University",
    "Arunachal University of Studies",
    "Arunodaya University",
    "Aryabhatta Knowledge University",
    "Ashoka University",
    "Assam Agricultural University",
    "Assam Don Bosco University",
    "Assam Down Town University",
    "Assam Rajiv Gandhi University of Cooperative Management",
    "Assam Science and Technology University",
    "Assam University",
    "Assam Women's University",
    "Atal Bihari Vajpayee Hindi Vishwavidyalaya",
    "Atal Bihari Vajpayee Vishwavidyalaya",
    "Atmiya University",
    "AURO University",
    "Avantika University",
    "Avinashilingam Institute for Home Science and Higher Education for Women",
    "Awadhesh Pratap Singh University",
    "Ayush and Health Sciences University of Chhattisgarh",
    "Azim Premji University",
    "B.S. Abdur Rahman Crescent Institute of Science and Technology",
    "Baba Farid University of Health Sciences",
    "Baba Ghulam Shah Badhshah University",
    "Baba Mastnath University",
    "Babasaheb Bhimrao Ambedkar Bihar University",
    "Babasaheb Bhimrao Ambedkar University",
    "Babu Banarasi Das University",
    "Baddi University of Emerging Sciences and Technologies",
    "BAHRA University",
    "Banaras Hindu University",
    "Banasthali Vidyapith",
    "Banda University of Agriculture and Technology",
    "Bangalore University",
    "Bankura University",
    "Bareilly International University",
    "Barkatullah University",
    "Bengaluru North University",
"Bennett University	Greater",
    "Berhampur University",
    "Bhabha University",
"Bhagat Phool Singh Mahila Vishwavidyalaya	Khanpur",
    "Bhagwant University",
    "Bhakta Kavi Narsinh Mehta University",
    "Bharath Institute of Higher Education and Research",
    "Bharathiar University",
    "Bharathidasan University",
    "Bharati Vidyapeeth Deemed University",
    "Bhartiya Skill Development University",
    "Bhatkhande Sanskriti Vishwavidyalaya",
    "Bhupal Nobles University",
    "Bhupendra Narayan Mandal University",
    "Bidhan Chandra Krishi Viswavidyalaya",
    "Bihar Agricultural University",
    "Biju Patnaik University of Technology",
    "Binod Bihari Mahto Koyalanchal University",
    "Birla Institute of Technology",
    "Birla Institute of Technology and Science",
    "Birsa Agricultural University",
    "BLDE University",
    "BML Munjal University",
    "Bodoland University",
    "Brainware University",
    "Bundelkhand University",
    "C.U. Shah University",
    "Career Point University",
    "Central Agricultural University",
    "Central Institute of Fisheries Education",
    "Central Institute of Higher Tibetan Studies",
    "Central University of Andhra Pradesh",
    "Central University of Gujarat",
    "Central University of Haryana",
    "Central University of Himachal Pradesh",
    "Central University of Jammu",
    "Central University of Jharkhand",
    "Central University of Karnataka",
    "Central University of Kashmir",
    "Central University of Kerala",
    "Central University of Odisha",
    "Central University of Punjab",
    "Central University of Rajasthan",
    "Central University of South Bihar",
    "Central University of Tamil Nadu",
    "Centurion University of Technology and Management",
    "CEPT University",
    "Chanakya National Law University",
    "Chandigarh University",
    "Chandra Shekhar Azad University of Agriculture and Technology",
    "Charotar University of Science and Technology",
    "Chaudhary Bansi Lal University",
    "Chaudhary Charan Singh Haryana Agricultural University",
    "Chaudhary Charan Singh University",
    "Chaudhary Devi Lal University",
    "Chaudhary Ranbir Singh University",
    "Chennai Mathematical Institute",
    "Chhatrapati Shahu Ji Maharaj University",
    "Chhattisgarh Kamdhenu Vishwavidyalaya",
    "Chhattisgarh Swami Vivekananda Technical University",
    "Children's University",
    "Chitkara University, Himachal Pradesh",
    "Chitkara University, Punjab",
    "Christ University",
    "Cluster University of Jammu",
    "CMJ University",
    "CMR University",
    "Cochin University of Science and Technology",
"Cooch Behar Panchanan Barma University	Cooch",
    "Cotton University",
    "CSK Himachal Pradesh Krishi Vishvavidyalaya",
    "Dakshina Bharat Hindi Prachar Sabha",
    "Damodaram Sanjivayya National Law University",
    "Datta Meghe Institute of Higher Education & Research",
    "DAV University",
    "Davangere University",
    "Dayalbagh Educational Institute",
    "Dayananda Sagar University",
    "Deccan College Post-Graduate and Research Institute",
    "Deen Dayal Upadhyay Gorakhpur University",
    "Deenbandhu Chhotu Ram University of Science and Technology",
    "Delhi Pharmaceutical Sciences and Research University",
    "Delhi Technological University",
"Desh Bhagat University	Mandi",
    "Dev Sanskriti Vishwavidyalaya",
    "Devi Ahilya Vishwavidyalaya",
    "Dharmsinh Desai University",
    "Dhirubhai Ambani Institute of Information and Communication Technology",
    "Diamond Harbour Women's University",
    "Dibrugarh University",
    "DIT University",
    "Doon University",
    "Dr K.N. Modi University",
    "Dr YSR Architecture and Fine Arts University",
    "Dr. A.P.J Abdul Kalam University",
    "Dr. A.P.J. Abdul Kalam Technical University",
    "Dr. B R Ambedkar National Institute of Technology Jalandhar",
    "Dr. B.R. Ambedkar University",
    "Dr. B.R. Ambedkar University of Social Sciences",
    "Dr. Babasaheb Ambedkar Marathwada University",
    "Dr. Babasaheb Ambedkar Technological University",
    "Dr. Balasaheb Sawant Konkan Krishi Vidyapeeth",
    "Dr. Bhimrao Ambedkar University",
    "Dr. C.V. Raman University",
    "Dr. D.Y. Patil Vidyapeeth",
    "Dr. Harisingh Gour Vishwavidyalaya Sagar",
    "Dr. M.G.R. Educational and Research Institute",
    "Dr. N.T.R. University of Health Sciences",
    "Dr. Panjabrao Deshmukh Krishi Vidyapeeth",
    "Dr. Rajendra Prasad Central Agricultural University",
    "Dr. Ram Manohar Lohia Avadh University",
    "Dr. Ram Manohar Lohiya National Law University",
    "Dr. Sarvepalli Radhakrishnan Rajasthan Ayurved University",
    "Dr. Shakuntala Misra National Rehabilitation University",
    "Dr. Vishwanath Karad MIT World Peace University",
    "Dr. Y.S. Parmar University of Horticulture and Forestry",
    "Dr. Y.S.R. Horticultural University",
    "Dravidian University",
    "EIILM University",
    "Era University",
"Eternal University	Baru",
    "Fakir Mohan University",
    "Flame University",
    "Forest Research Institute",
    "G.H. Raisoni University",
    "G.L.S. University",
    "Galgotias University",
    "Gandhi Institute of Technology and Management",
    "Gandhigram Rural University",
    "Gangadhar Meher University",
    "Ganpat University",
    "Garden City University",
    "Gauhati University",
"Gautam Buddha University	Greater",
    "GD Goenka University",
    "Geetanjali University",
    "GIET University",
    "GLA University",
    "Glocal University",
    "GNA University",
    "Goa University",
    "Gokhale Institute of Politics and Economics",
    "Gondwana University",
    "Govind Ballabh Pant University of Agriculture and Technology",
    "Govind Guru Tribal University",
"Graphic Era Hill University	Clement",
    "Graphic Era University",
    "GSFC University",
    "Gujarat Ayurved University",
    "Gujarat National Law University",
    "Gujarat Technological University",
    "Gujarat University",
    "Gujarat University of Transplantation Sciences",
    "Gujarat Vidyapith",
    "Gulbarga University",
    "Guru Angad Dev Veterinary and Animal Sciences University",
    "Guru Ghasidas Vishwavidyalaya",
    "Guru Gobind Singh Indraprastha University",
    "Guru Jambheshwar University of Science and Technology",
"Guru Kashi University	Talwandi",
    "Guru Nanak Dev University",
    "Guru Ravidas Ayurved University",
    "Gurukul Kangri Vishwavidyalaya",
    "Harcourt Butler Technical University",
    "Haridev Joshi University of Journalism and Mass Communication",
    "Haridwar University",
    "Hemchandracharya North Gujarat University",
    "Hemwati Nandan Bahuguna Garhwal University",
    "Hemwati Nandan Bahuguna Uttarakhand Medical Education University",
    "Hidayatullah National Law University",
    "Himachal Pradesh Technical University",
    "Himachal Pradesh University",
    "Himalayan University",
    "Himgiri ZEE University",
    "Hindustan Institute of Technology and Science",
    "Homi Bhabha National Institute",
    "ICFAI University, Dehradun",
    "ICFAI University, Himachal Pradesh",
    "ICFAI University, Jaipur",
    "ICFAI University, Jharkhand",
    "ICFAI University, Meghalaya",
    "ICFAI University, Mizoram",
    "ICFAI University, Nagaland",
    "ICFAI University, Raipur",
    "ICFAI University, Sikkim",
    "ICFAI University, Tripura",
    "IEC University",
    "IFHE Hyderabad",
    "IFTM University",
    "IIHMR University",
    "IIMT University",
    "IMS Unison University",
    "Indian Agricultural Research Institute",
    "Indian Institute of Engineering Science and Technology, Shibpur",
    "Indian Institute of Foreign Trade",
    "Indian Institute of Information Technology Allahabad",
    "Indian Institute of Information Technology and Management Gwalior",
    "Indian Institute of Information Technology, Design and Manufacturing",
    "Indian Institute of Information Technology, Guwahati",
    "Indian Institute of Information Technology, Kalyani",
    "Indian Institute of Information Technology, Kottayam",
    "Indian Institute of Information Technology, Lucknow",
    "Indian Institute of Information Technology, Manipur",
    "Indian Institute of Information Technology, Pune",
"Indian Institute of Information Technology, Sri City	Sri",
    "Indian Institute of Information Technology, Una",
    "Indian Institute of Information Technology, Vadodara",
    "Indian Institute of Public Health",
    "Indian Institute of Science",
    "Indian Institute of Science Education and Research, Bhopal",
    "Indian Institute of Science Education and Research, Kolkata",
    "Indian Institute of Science Education and Research, Mohali",
    "Indian Institute of Science Education and Research, Pune",
    "Indian Institute of Science Education and Research, Thiruvananthapuram",
    "Indian Institute of Space Science and Technology",
    "Indian Institute of Teacher Education",
    "Indian Institute of Technology Bhubaneswar",
    "Indian Institute of Technology Bombay",
    "Indian Institute of Technology Delhi",
    "Indian Institute of Technology Gandhinagar",
    "Indian Institute of Technology Guwahati",
    "Indian Institute of Technology Hyderabad",
    "Indian Institute of Technology Indore",
    "Indian Institute of Technology Jodhpur",
    "Indian Institute of Technology Kanpur",
    "Indian Institute of Technology Kharagpur",
    "Indian Institute of Technology Madras",
    "Indian Institute of Technology Mandi",
    "Indian Institute of Technology Patna",
    "Indian Institute of Technology Roorkee",
    "Indian Institute of Technology Ropar",
    "Indian Institute of Technology, BHU",
    "Indian Maritime University",
    "Indian School of Mines",
    "Indian Statistical Institute",
    "Indian Veterinary Research Institute",
    "Indira Gandhi Delhi Technical University for Women",
    "Indira Gandhi Institute of Development Research",
    "Indira Gandhi Institute of Medical Sciences",
    "Indira Gandhi Krishi Vishwavidyalaya",
    "Indira Gandhi National Tribal University",
    "Indira Gandhi Technological and Medical Sciences University",
    "Indira Gandhi University, Meerpur",
    "Indira Kala Sangeet Vishwavidyalaya",
    "Indraprastha Institute of Information Technology",
    "Indrashil University",
    "Indus International University",
    "Indus University",
    "Institute of Advanced Research",
    "Institute of Advanced Studies in Education",
    "Institute of Chemical Technology",
    "Institute of Infrastructure, Technology, Research and Management",
    "Integral University",
    "International Institute for Population Sciences",
    "International Institute of Information Technology Bangalore",
    "International Institute of Information Technology, Bhubaneswar",
    "International Institute of Information Technology, Hyderabad",
"International Institute of Information Technology, Naya Raipur	Naya",
    "Invertis University",
    "ISBM University",
    "Islamic University of Science and Technology",
    "ITM University Gwalior",
    "ITM University Raipur",
    "ITM Vocational University",
    "J.C. Bose University of Science and Technology",
    "Jadavpur University",
    "Jagadguru Ramanandacharya Rajasthan Sanskrit University",
    "Jagadguru Rambhadracharya Handicapped University",
    "Jagan Nath University",
    "Jagannath University",
    "Jagran Lakecity University",
    "Jai Narain Vyas University",
    "Jai Prakash Vishwavidyalaya",
    "Jain University",
    "Jain Vishva Bharati Institute",
    "Jaipur National University",
    "Jamia Hamdard",
    "Jamia Millia Islamia",
    "Jananayak Chandrashekhar University",
    "Janardan Rai Nagar Rajasthan Vidhyapeeth University",
    "Jawaharlal Institute of Postgraduate Medical Education & Research",
    "Jawaharlal Nehru Architecture and Fine Arts University",
    "Jawaharlal Nehru Centre for Advanced Scientific Research",
    "Jawaharlal Nehru Krishi Vishwavidyalaya",
    "Jawaharlal Nehru Technological University",
    "Jawaharlal Nehru Technological University, Anantapur",
    "Jawaharlal Nehru Technological University, Kakinada",
    "Jawaharlal Nehru University",
    "Jayoti Vidyapeeth Women's University",
    "Jaypee Institute of Information Technology",
    "Jaypee University Anoopshahr",
    "Jaypee University of Engineering and Technology",
    "Jaypee University of Information Technology",
    "JECRC University",
    "Jharkhand Rai University",
    "Jharkhand Raksha Shakti University",
    "JIS University",
    "Jiwaji University",
    "JK Lakshmipat University",
    "Jodhpur National University",
    "JS University",
    "JSS Academy of Higher Education and Research",
    "JSS Science and Technology University",
    "Junagadh Agricultural University",
    "K L University",
    "K.K. University",
    "K.R. Mangalam University",
    "Kadi Sarva Vishwavidyalaya",
    "Kakatiya University",
    "Kalasalingam Academy of Research and Education",
    "Kalinga University",
    "Kamdhenu University",
    "Kameshwar Singh Darbhanga Sanskrit University",
    "Kannada University",
    "Kannur University",
    "Karnatak University",
    "Karnataka Janapada Vishwavidyalaya",
    "Karnataka Samskrit University",
    "Karnataka State Akkamahadevi Women's University",
    "Karnataka State Law University",
    "Karnataka State Rural Development and Panchayat Raj University",
    "Karnataka Veterinary, Animal and Fisheries Sciences University",
    "Karnavati University",
    "Karpagam Academy of Higher Education",
    "Karunya Institute of Technology and Sciences",
    "Kavayitri Bahinabai Chaudhari North Maharashtra University",
    "Kavi Kulguru Kalidas Sanskrit Vishwavidyalaya",
    "Kazi Nazrul University",
    "Kaziranga University",
    "Kerala Agricultural University",
    "Kerala Kalamandalam",
    "Kerala University of Fisheries and Ocean Studies",
    "Kerala University of Health Sciences",
    "Kerala Veterinary and Animal Sciences University",
    "Khwaja Moinuddin Chishti Language University",
    "KIIT University",
    "King George's Medical University",
    "KLE Technological University",
    "KLE University",
    "Kolhan University",
    "Krantiguru Shyamji Krishna Verma Kachchh University",
    "Krishna University",
    "Krishna Vishwa Vidyapeeth",
    "KSGH Music and Performing Arts University",
    "Kumar Bhaskar Varma Sanskrit and Ancient Studies University",
    "Kumaun University",
    "Kurukshetra University",
    "Kushabhau Thakre Patrakarita Avam Jansanchar University",
    "Kuvempu University",
    "Lakshmibai National Institute of Physical Education",
    "Lakulish Yoga University",
    "Lala Lajpat Rai University of Veterinary and Animal Sciences",
    "Lalit Narayan Mithila University",
    "Lingaya's University",
    "LNCT University",
    "Lovely Professional University",
    "M.J.P. Rohilkhand University",
    "Madan Mohan Malaviya University of Technology",
    "Madhav University",
    "Madhyanchal Professional University",
    "Madurai Kamaraj University",
"Magadh University	Bodh",
    "Mahapurusha Srimanta Sankaradeva Viswavidyalaya",
"Maharaja Agrasen Himalayan Garhwal University	Pokhara",
    "Maharaja Agrasen University",
    "Maharaja Bir Bikram University",
    "Maharaja Chhatrasal Bundelkhand University",
    "Maharaja Ganga Singh University",
    "Maharaja Krishnakumarsinhji Bhavnagar University",
    "Maharaja Ranjit Singh Punjab Technical University",
    "Maharaja Surajmal Brij University",
    "Maharana Pratap University of Agriculture and Technology",
    "Maharashtra Animal and Fishery Sciences University",
    "Maharashtra National Law University Mumbai",
    "Maharashtra National Law University, Aurangabad",
    "Maharashtra National Law University, Nagpur",
    "Maharashtra University of Health Sciences",
"Maharishi Arvind University, Jaipur	Mundiya",
    "Maharishi Dayanand University",
    "Maharishi Mahesh Yogi Vedic Vishwavidyalaya",
    "Maharishi Markandeshwar University, Mullana",
    "Maharishi Markandeshwar University, Sadopur",
    "Maharishi Markandeshwar University, Solan",
    "Maharishi University",
    "Maharishi University of Management and Technology",
    "Maharshi Dayanand Saraswati University",
    "Maharshi Panini Sanskrit Vishwavidyalaya",
    "Mahatma Gandhi Antarrashtriya Hindi Vishwavidyalaya",
    "Mahatma Gandhi Central University, Motihari",
    "Mahatma Gandhi Chitrakoot Gramoday Vishwavidyalaya",
    "Mahatma Gandhi Kashi Vidyapeeth",
    "Mahatma Gandhi University",
    "Mahatma Gandhi University of Medical Sciences and Technology",
    "Mahatma Gandhi University, Meghalaya",
    "Mahatma Gandhi University, Nalgonda",
    "Mahatma Jyoti Rao Phoole University",
    "Mahatma Phule Krishi Vidyapeeth",
    "Makhanlal Chaturvedi Rashtriya Patrakarita Vishwavidyalaya",
    "Malaviya National Institute of Technology, Jaipur",
    "Malwanchal University",
    "Manav Bharti University",
    "Manav Rachna International Institute of Research and Studies",
    "Manav Rachna University",
    "Mandsaur University",
    "Mangalayatan University",
    "Mangalore University",
    "Manipal Academy of Higher Education",
    "Manipur International University",
    "Manipur Technical University",
    "Manipur University",
    "Manipur University of Culture",
    "Manonmaniam Sundaranar University",
    "Martin Luther Christian University",
    "Marwadi University",
    "MATS University",
    "Maulana Abul Kalam Azad University of Technology, West Bengal",
    "Maulana Azad National Institute of Technology",
    "Maulana Azad National Urdu University",
    "Maulana Azad University, Jodhpur",
    "Maulana Mazharul Haque Arabic and Persian University",
    "Medi-Caps University",
    "Meenakshi Academy of Higher Education and Research",
    "Mewar University",
"MGM Institute of Health Sciences	Navi",
    "MIT Art Design and Technology University",
    "MIT University",
    "Mizoram University",
    "Mody University of Science and Technology",
    "Mohammad Ali Jauhar University",
    "Mohanlal Sukhadia University",
    "Monad University",
    "Mother Teresa Women's University",
    "Motherhood University",
    "Motilal Nehru National Institute of Technology Allahabad",
    "Murshidabad University",
    "MVN University",
    "Nagaland University",
    "Nalanda University",
    "NALSAR University of Law",
    "Nanaji Deshmukh Veterinary Science University",
    "Narsee Monjee Institute of Management and Higher Studies",
    "National Dairy Research Institute",
    "National Forensic Sciences University",
    "National Institute of Design",
    "National Institute of Educational Planning and Administration",
    "National Institute of Fashion Technology",
    "National Institute of Food Technology Entrepreneurship and Management",
    "National Institute of Mental Health and Neuro Sciences",
    "National Institute of Pharmaceutical Education and Research, Ahmedabad",
    "National Institute of Pharmaceutical Education and Research, Guwahati",
    "National Institute of Pharmaceutical Education and Research, Hajipur",
    "National Institute of Pharmaceutical Education and Research, Hyderabad",
    "National Institute of Pharmaceutical Education and Research, Kolkata",
"National Institute of Pharmaceutical Education and Research, Raebareli	Rae",
    "National Institute of Pharmaceutical Education and Research, S.A.S. Nagar",
    "National Institute of Technology Delhi",
    "National Institute of Technology Mizoram",
    "National Institute of Technology Sikkim",
    "National Institute of Technology, Agartala",
    "National Institute of Technology, Arunachal Pradesh",
    "National Institute of Technology, Calicut",
    "National Institute of Technology, Durgapur",
    "National Institute of Technology, Goa",
    "National Institute of Technology, Hamirpur",
    "National Institute of Technology, Jamshedpur",
    "National Institute of Technology, Karnataka",
    "National Institute of Technology, Kurukshetra",
    "National Institute of Technology, Manipur",
    "National Institute of Technology, Meghalaya",
    "National Institute of Technology, Nagaland",
    "National Institute of Technology, Patna",
    "National Institute of Technology, Puducherry",
    "National Institute of Technology, Raipur",
    "National Institute of Technology, Rourkela",
    "National Institute of Technology, Silchar",
    "National Institute of Technology, Srinagar",
    "National Institute of Technology, Tiruchirappalli",
    "National Institute of Technology, Uttarakhand",
    "National Institute of Technology, Warangal",
    "National Law Institute University",
    "National Law School of India University",
    "National Law University and Judicial Academy",
    "National Law University Odisha",
    "National Law University, Delhi",
    "National Law University, Jodhpur",
    "National Sanskrit University",
    "National Sports University",
    "National University of Study and Research in Law",
    "Nava Nalanda Mahavihara",
    "Navrachana University",
    "Navsari Agricultural University",
    "Nehru Gram Bharati Vishwavidyalaya",
    "NIILM University",
    "NIIT University",
    "Nilamber-Pitamber University",
    "NIMS University",
    "Nirma University",
    "NITTE University",
    "Nizam's Institute of Medical Sciences",
"Noida International University	Greater",
    "North East Frontier Technical University",
    "North Eastern Hill University",
    "North Eastern Regional Institute of Science and Technology",
    "North Orissa University",
    "O.P. Jindal Global University",
    "O.P. Jindal University",
    "Odisha University of Agriculture and Technology",
    "OPJS University",
    "Oriental University",
    "Osmania University",
    "P P Savani University",
"P.K. University	Village",
    "Pacific Medical University",
    "Pacific University, India",
"Padmashree Dr. D.Y. Patil Vidyapith	Navi",
    "Palamuru University",
    "Pandit Deendayal Petroleum University",
    "Pandit Deendayal Upadhyaya Shekhawati University",
    "Pandit Ravishankar Shukla University",
    "Panjab University",
    "Parul University",
    "Patna University",
    "PDM University",
    "PDPM Indian Institute of Information Technology, Design and Manufacturing",
    "PEC University of Technology",
    "People's University",
    "Periyar Maniammai Institute of Science and Technology",
    "Periyar University",
    "PES University",
    "Plastindia International University",
    "Pondicherry University",
    "Ponnaiyan Ramajayam Institute of Science and Technology",
    "Poornima University",
    "Post Graduate Institute of Medical Education and Research",
    "Potti Sreeramulu Telugu University",
    "Pragyan International University",
    "Pratap University",
    "Pravara Institute of Medical Sciences",
    "Presidency University",
    "Presidency University",
    "Prof. Rajendra Singh (Rajju Bhaiya) University",
    "Professor Jayashankar Telangana State Agricultural University",
    "Pt. Bhagwat Dayal Sharma University of Health Sciences",
    "Punjab Agricultural University",
    "Punjab Technical University",
    "Punjabi University Patiala",
    "Punyashlok Ahilyadevi Holkar Solapur University",
    "Quantum University",
    "Rabindra Bharati University",
"Rabindranath Tagore University	Mendua",
    "Raffles University",
    "Rai Technology University",
    "Rai University",
    "Raiganj University",
    "Raj Rishi Bharthari Matsya University",
    "Raja Mansingh Tomar Music and Arts University",
    "Rajasthan Technical University Kota",
    "Rajasthan University of Health Sciences",
    "Rajasthan University of Veterinary and Animal Sciences",
    "Rajiv Gandhi Institute of Petroleum Technology",
    "Rajiv Gandhi National Aviation University",
    "Rajiv Gandhi National Institute of Youth Development",
    "Rajiv Gandhi National University of Law",
    "Rajiv Gandhi Proudyogiki Vishwavidyalaya",
    "Rajiv Gandhi University",
    "Rajiv Gandhi University of Health Sciences",
    "Rajiv Gandhi University of Knowledge Technologies",
    "Rajmata Vijayaraje Scindia Krishi Vishwavidyalaya",
    "Rama Devi Women's University",
    "Rama University",
    "Ramaiah University of Applied Sciences",
    "Ramakrishna Mission Vivekananda Educational and Research Institute",
    "Ranchi University",
    "Rani Channamma University, Belagavi",
    "Rani Durgavati Vishwavidyalaya",
    "Rani Lakshmi Bai Central Agricultural University",
    "Ras Bihari Bose Subharti University",
    "Rashtrasant Tukadoji Maharaj Nagpur University",
    "Rashtriya Raksha University",
    "Rashtriya Sanskrit Sansthan University",
    "Ravenshaw University",
    "Rayalaseema University",
    "Rayat-Bahra University",
    "REVA University",
"RIMT University	Mandi",
    "Rishihood University",
    "RK University",
    "RKDF University",
    "Sabarmati University",
    "Sage University",
    "Sai Nath University",
    "Sai Tirupati University",
    "Sam Higginbottom Institute of Agriculture, Technology and Sciences",
    "Sambalpur University",
    "Sampurnanand Sanskrit Vishvavidyalaya",
    "Sanchi University of Buddhist-Indic Studies",
    "Sandip University",
    "Sandip University, Sijoul",
    "Sangai International University",
    "Sangam University",
    "Sanjay Gandhi Post Graduate Institute of Medical Sciences",
    "Sanjay Ghodawat University",
    "Sankalchand Patel University",
    "Sanskriti University",
    "Sant Baba Bhag Singh University",
    "Sant Gadge Baba Amravati University",
    "Sant Longowal Institute of Engineering and Technology",
    "Santosh University",
    "Sarala Birla University",
"Sardar Patel University	Vallabh",
    "Sardar Patel University of Police, Security and Criminal Justice",
    "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Sardar Vallabhbhai Patel University of Agriculture and Technology",
    "Sardarkrushinagar Dantiwada Agricultural University",
    "Sarguja University",
    "Sarvepalli Radhakrishnan University",
    "SASTRA University",
    "Satavahana University",
    "Sathyabama Institute of Science and Technology",
    "Saurashtra University",
    "Saveetha Institute of Medical and Technical Sciences",
    "Savitribai Phule Pune University",
    "School of Planning and Architecture, Bhopal",
    "School of Planning and Architecture, Delhi",
    "School of Planning and Architecture, Vijayawada",
    "Seacom Skills University",
    "Shaheed Mahendra Karma Vishwavidyalaya",
"Sharda University	Greater",
    "Sharnbasva University",
    "Sher-e-Kashmir University of Agricultural Sciences and Technology of Jammu",
    "Sher-e-Kashmir University of Agricultural Sciences and Technology of Kashmir",
    "Sher-i-Kashmir Institute of Medical Sciences",
    "Shiv Nadar University",
    "Shivaji University",
    "Shobhit Institute of Engineering and Technology",
    "Shoolini University of Biotechnology and Management Sciences",
    "Shree Guru Gobind Singh Tricentenary University",
    "Shree Somnath Sanskrit University",
    "Shreemati Nathibai Damodar Thackersey Women's University",
    "Shri Govind Guru University",
    "Shri Guru Ram Rai Education Mission",
    "Shri Guru Ram Rai University",
    "Shri Jagannath Sanskrit Vishvavidyalaya",
    "Shri Jagdishprasad Jhabrmal Tibrewala University",
    "Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha",
    "Shri Mata Vaishno Devi University",
    "Shri Ramswaroop Memorial University",
    "Shri Vaishnav Vidyapeeth Vishwavidyalaya",
    "Shri Venkateshwara University",
    "Shri Vishwakarma Skill University",
    "Shridhar University",
    "Siddharth University",
    "Sidho Kanho Birsha University",
    "Sido Kanhu Murmu University",
    "Sikkim Manipal University",
    "Sikkim University",
    "Siksha 'O' Anusandhan",
    "Singhania University",
    "Sir Padampat Singhania University",
    "South Asian University",
    "Spicer Adventist University",
    "Sree Chitra Thirunal Institute of Medical Sciences and Technology",
    "Sree Sankaracharya University of Sanskrit",
    "Sri Balaji Vidyapeeth",
    "Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya",
    "Sri Dev Suman Uttarakhand University",
    "Sri Devaraj Urs Academy of Higher Education and Research",
"Sri Guru Granth Sahib World University	Fatehgarh",
"Sri Guru Ram Das University of Health Sciences	Qila Jiwan",
    "Sri Konda Laxman Telangana State Horticultural University",
    "Sri Krishnadevaraya University",
    "Sri Padmavati Mahila Visvavidyalayam",
    "Sri Ramachandra Institute of Higher Education and Research",
    "Sri Sai University",
    "Sri Sathya Sai Institute of Higher Learning",
    "Sri Satya Sai University of Technology and Medical Sciences",
    "Sri Siddhartha Academy of Higher Education",
    "Sri Sri University",
    "Sri Venkateswara Institute of Medical Sciences",
    "Sri Venkateswara University",
    "Sri Venkateswara Vedic University",
    "Sri Venkateswara Veterinary University",
    "Srimanta Sankaradeva University of Health Sciences",
    "Srinivas University",
    "SRM Institute of Science and Technology",
    "SRM University Haryana",
    "SRM University, Sikkim",
    "St. Joseph University",
    "St. Peter's Institute of Higher Education and Research",
    "St. Xavier's University",
    "Starex University",
    "State University of Performing and Visual Arts",
    "Suamandeep Vidyapeeth",
    "Sunrise University",
    "Suresh Gyan Vihar University",
    "Sushant University",
    "Swami Keshwanand Rajasthan Agricultural University",
    "Swami Rama Himalayan University",
    "Swami Ramanand Teerth Marathwada University",
    "Swami Vivekanand Subharti University",
    "Swami Vivekanand University",
    "Swami Vivekananda Yoga Anusandhana Samsthana",
    "Swarnim Gujarat Sports University",
    "Swarnim Startup and Innovation University",
    "Symbiosis International University",
    "Symbiosis University of Applied Sciences",
    "Tamil Nadu Agricultural University",
    "Tamil Nadu Dr Ambedkar Law University",
    "Tamil Nadu Dr. J. Jayalalithaa Fisheries University",
    "Tamil Nadu Dr. M.G.R.Medical University",
    "Tamil Nadu Dr.J Jayalalithaa Music and Fine Arts University",
    "Tamil Nadu National Law University",
    "Tamil Nadu Physical Education and Sports University",
    "Tamil Nadu Teacher Education University",
    "Tamil Nadu Veterinary and Animal Sciences University",
    "Tamil University",
"Tantia University	Sri",
    "Tata Institute of Fundamental Research",
    "Tata Institute of Social Sciences",
    "TeamLease Skills University",
    "Techno India University",
    "Teerthanker Mahaveer University",
    "Telangana University",
    "TERI School of Advanced Studies",
    "Tezpur University",
    "Thapar Institute of Engineering and Technology",
    "The English and Foreign Languages University",
    "The IIS University",
    "The Indian Law Institute",
    "The LNM Institute of Information Technology",
    "The Maharaja Sayajirao University of Baroda",
    "The National University of Advanced Legal Studies",
    "The Neotia University",
    "The Northcap University",
    "The Sanskrit College and University",
    "The West Bengal National University of Juridical Sciences",
    "Thiruvalluvar University",
    "Thunchath Ezhuthachan Malayalam University",
    "Tilak Maharashtra Vidyapeeth",
    "Tilka Manjhi Bhagalpur University",
    "Tripura University",
    "Tumkur University",
    "U.P. Pt. Deen Dayal Upadhyaya Veterinary Science University and Cattle Research Institute",
    "Uka Tarsadia University",
    "University of Agricultural and Horticultural Sciences, Shivamogga",
    "University of Agricultural Sciences, Bangalore",
    "University of Agricultural Sciences, Dharwad",
    "University of Agricultural Sciences, Raichur",
    "University of Allahabad",
    "University of Burdwan",
    "University of Calcutta",
    "University of Calicut",
    "University of Delhi",
    "University of Engineering and Management, Kolkata",
    "University of Gour Banga",
    "University of Horticultural Sciences, Bagalkot",
    "University of Hyderabad",
    "University of Jammu",
    "University of Kalyani",
    "University of Kashmir",
    "University of Kerala",
    "University of Kota",
    "University of Lucknow",
    "University of Madras",
    "University of Mumbai",
    "University of Mysore",
    "University of North Bengal",
    "University of Patanjali",
    "University of Petroleum and Energy Studies",
    "University of Rajasthan",
    "University of Science and Technology, Meghalaya",
    "University of Technology",
    "University of Trans-Disciplinary Health Sciences and Technology",
    "Usha Martin University",
    "Utkal University",
    "Utkal University of Culture",
    "Uttar Banga Krishi Viswavidyalaya",
    "Uttar Pradesh University of Medical Sciences",
    "Uttarakhand Aawasiya Vishwavidyalaya, Almora",
    "Uttarakhand Ayurved University",
    "Uttarakhand Sanskrit University",
    "Uttarakhand Technical University",
    "Uttaranchal University",
    "Vasantrao Naik Marathwada Krishi Vidyapeeth",
    "Veer Bahadur Singh Purvanchal University",
    "Veer Chandra Singh Garhwali Uttarakhand University of Horticulture & Forestry",
    "Veer Kunwar Singh University",
    "Veer Narmad South Gujarat University",
    "Veer Surendra Sai University of Technology",
    "Vel Tech Rangarajan Dr. Sagunthala R&D Institute of Science and Technology",
    "Vellore Institute of Technology",
    "Vels University",
    "Vidyasagar University",
    "Vignan University",
    "Vijayanagara Sri Krishnadevaraya University",
    "Vikram University",
    "Vikrama Simhapuri University",
    "Vinayaka Mission's Research Foundation",
    "Vinayaka Missions Sikkim University",
    "Vinoba Bhave University",
    "Vishwakarma University",
    "Visva-Bharati University",
    "Visvesvaraya National Institute of Technology",
    "Visvesvaraya Technological University",
    "VIT Bhopal University",
    "Vivekananda Global University",
    "West Bengal State University",
    "West Bengal University of Animal and Fishery Sciences",
    "West Bengal University of Health Sciences",
    "West Bengal University of Teachers' Training, Education Planning and Administration",
    "William Carey University, Shillong",
    "XIM University",
    "YBN University",
    "Yenepoya University",
    "Yogi Vemana University"
]