import React from 'react'
import { motion } from "framer-motion"

const AnimatedPage = ({children}) => {
    const animations = {
        initial:{opacity: 0, x: 0},
        animate:{opacity: 100, x: 0},
        exit:{opacity: 0, x: 0},
        transition:{ duration: 1 },
    }


  return (
    <motion.div variants={animations} initial="initial" animate="animate" exit="exit" transition="transition">
        {children}
    </motion.div>
  )
}

export default AnimatedPage