import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './style.css';
import GrayCheck from '../../images/gray-Check.svg';
import WhiteCheck from '../../images/white-check.svg';
import WOW from 'wowjs';
import 'animate.css';
import { Link } from 'react-router-dom';
import { addCommas } from '../../Components/common/common';


const MINI_STUDENTS = 50;


const MONTHLY_PACKAGE = 2000;
const YEARLY_PACKAGE = 24000;
function PricePage() {
  const [activeTab, setActiveTab] = useState(0); // State to track active tab index





  const [value, setValue] = useState(1);
  const [selectPackage, setSelectPackage] = useState("monthly");
  const [numberOfStudent, setNumberOfStudent] = useState(50);
  const [thumbWidth, setThumbWidth] = useState(200);

  console.log("numberOfStudent", numberOfStudent)

  const handleClick = (index, name) => {
    setActiveTab(index); // Update active tab index when a tab is clicked
    setSelectPackage(name)
  };


  const selectedPackage = useMemo(() => {
    if (selectPackage === "monthly") {
      return MONTHLY_PACKAGE;
    } else {
      return YEARLY_PACKAGE;
    }
  }, [selectPackage])


  const calculateStudentsPackage = useMemo(() => {
    if (selectPackage === "monthly") {
      return numberOfStudent * MONTHLY_PACKAGE / MINI_STUDENTS;
    } else {
      return numberOfStudent * YEARLY_PACKAGE / MINI_STUDENTS;
    }


  }, [selectPackage, numberOfStudent])

  const calculatePerStudentsPackage = useMemo(() => {
    if (selectPackage === "monthly") {
      return calculateStudentsPackage / numberOfStudent;
    } else {
      return calculateStudentsPackage / numberOfStudent;
    }


  }, [selectPackage, numberOfStudent])

  console.log("calculatePerStudentsPackage", calculatePerStudentsPackage)

  const handleInputChange = (event) => {
    console.log("event.target.value", typeof event.target.value)
    let val = parseInt(event.target.value, 10);
    if (val < 1) val = '< 1';
    setValue(val);

    if (event.target.value === "1") {
      setNumberOfStudent(50);
    } else if (event.target.value === "2") {
      setNumberOfStudent(500);
    } else if (event.target.value === "3") {
      setNumberOfStudent(1000);
    } else if (event.target.value === "4") {
      setNumberOfStudent(2000);
    } else if (event.target.value === "5") {
      setNumberOfStudent(3000);
    } else if (event.target.value === "6") {
      setNumberOfStudent(4000);
    } else {
      setNumberOfStudent(5000);
    }


  };

  const updateSliderPosition = useCallback(() => {
    const inputElement = document.getElementById('dae1');
    if (inputElement) {
      const min = parseInt(inputElement.min, 10);
      const max = parseInt(inputElement.max, 10);
      const pos = (value - min) / (max - min);

      const thumbCorrect = thumbWidth * (pos - 0.5) * -1;
      const titlePos = Math.round(pos * inputElement.clientWidth - thumbWidth / 4 + thumbCorrect);

      const currentValueElement = document.querySelector('.current-value');
      if (currentValueElement) {
        currentValueElement.style.left = `${titlePos}px`;
      }

      const gradientPosition = Math.round(pos * 99);
      const gradient = `linear-gradient(90deg, #136ef1 ${gradientPosition}%,#C7DEFF ${gradientPosition + 1}%)`;
      inputElement.style.background = gradient;
    }
  }, [value, thumbWidth]);

  useEffect(() => {
    updateSliderPosition();
    window.addEventListener('resize', updateSliderPosition);

    return () => {
      window.removeEventListener('resize', updateSliderPosition);
    };
  }, [value, updateSliderPosition]);

  useEffect(() => {
    new WOW.WOW().init();
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scrolling smooth
    });
  }, []);

  useEffect(() => {
    const rangeInput = document.getElementById('dae1');
    const percentage = ((value - rangeInput.min) / (rangeInput.max - rangeInput.min)) * 100;
    rangeInput.style.setProperty('--range-progress', `${percentage}%`);
  }, [value]);




  return (
    <>
      <div className='md:mt-20 mt-10'>
        <div className='container mx-auto px-4 md:pt-5 pt-10 relative z-20'>
          <div className='grid grid-cols-12 gap-4 items-center'>
            <div className='heading lg:col-span-8 col-span-12'>
              <h3 className='md:text-4xl text-xl font-bold text-regal-blue md:mb-2 mb-1 wow animate__animated animate__fadeInUp priceHeading' data-wow-duration="1s" data-wow-delay="0.2s">Simple, transparent pricing </h3>
              <p className='md:text-lg text-md text-gray-600 mb-4 max-w-2xl wow animate__animated animate__fadeInUp priceSubheading' data-wow-duration="1s" data-wow-delay="0.5s">No Contracts. No Surprise fees.</p>
            </div>
            <div className='lg:col-span-4 col-span-12 text-end'>
              <div className='Customtabs wow animate__animated animate__fadeInRight' data-wow-duration="1s" data-wow-delay="0.2s">
                <ul>
                  <li className={activeTab === 0 ? 'active' : ''}>
                    <button
                      onClick={() => handleClick(0, "monthly")}
                    >
                      Monthly
                    </button>
                  </li>
                  <li className={activeTab === 2 ? 'active' : ''}>
                    <button

                      onClick={() => handleClick(2, "yearly")}
                    >
                      Yearly
                    </button>
                  </li>
                  <span className='active-bg'></span>
                </ul>

              </div>
            </div>
          </div>

          <div className='selectStudents'>Slide to select the number of students in your institute</div>
          <div className="slider">
            <div className="range1">
              <input
                type="range"
                name="date"
                id="dae1"
                min="1"
                max="7"
                // value={typeof value === 'string' ? value.replace('< ', '') : value}
                value={value}
                onChange={handleInputChange}
                onInput={handleInputChange}
                required
                style={{ background: `linear-gradient(to right, #4CAF50 ${((value - 1) / (7 - 1)) * 100}%, #ddd ${((value - 1) / (7 - 1)) * 100}%)` }}
              />


              <div className="step-labels">
                <span>50</span>
                <span>500</span>
                <span>1000</span>
                <span>2000</span>
                <span>3000</span>
                <span>4000</span>
                <span>5000+</span>
              </div>
            </div>
          </div>


          <div className='current-plan'>
            <div className='selected-price'><span>₹</span><span className='TotalPrice'>{addCommas(calculateStudentsPackage)}</span></div>
            <p className='price-per-student'><span>₹</span><span className='PricePerStudent'>{calculatePerStudentsPackage}</span> Per student/{selectPackage === "monthly" ? "month" : "year"}</p>
            <a href='#' className='EnrollNow'>Enroll Now <i><svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.64012 6.99999L17.3599 6.99999M17.3599 6.99999L11.6934 1.33499M17.3599 6.99999L11.6934 12.665" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg></i></a>
            <p className='Scheduledemo'>
              {"Want to see it in action first? "} 

              <Link to="/demo">{"Schedule a free demo."}</Link>
            
            </p>

            <div className='IncludedFeatures'>
              <h3>Included Features</h3>
              <ul>
                <li><svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="17" height="17" rx="8.5" fill="#136EF1" fill-opacity="0.22" />
                  <path d="M12 6L8.13793 10.5308C7.60469 11.1564 6.74014 11.1564 6.2069 10.5308L5 9.11494" stroke="#136EF1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Real-time Attendance Tracking</li>
                <li><svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="17" height="17" rx="8.5" fill="#136EF1" fill-opacity="0.22" />
                  <path d="M12 6L8.13793 10.5308C7.60469 11.1564 6.74014 11.1564 6.2069 10.5308L5 9.11494" stroke="#136EF1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Customizable Institute Setup</li>
                <li><svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="17" height="17" rx="8.5" fill="#136EF1" fill-opacity="0.22" />
                  <path d="M12 6L8.13793 10.5308C7.60469 11.1564 6.74014 11.1564 6.2069 10.5308L5 9.11494" stroke="#136EF1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Automated User Onboarding</li>
                <li><svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="17" height="17" rx="8.5" fill="#136EF1" fill-opacity="0.22" />
                  <path d="M12 6L8.13793 10.5308C7.60469 11.1564 6.74014 11.1564 6.2069 10.5308L5 9.11494" stroke="#136EF1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Multiple Admin & Coordinator Accounts</li>
                <li><svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.5" width="17" height="17" rx="8.5" fill="#136EF1" fill-opacity="0.22" />
                  <path d="M12 6L8.13793 10.5308C7.60469 11.1564 6.74014 11.1564 6.2069 10.5308L5 9.11494" stroke="#136EF1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Comprehensive Analytics Dashboard</li>
              </ul>
            </div>
          </div>


          <div className='EnhanceYourExperience'>
            <h3>Enhance Your Experience</h3>
            <p>Unlock additional capabilities with our optional add-ons, <span>available after enrollment.</span></p>

            <div className='addonsItems'>
              <div className='AddOnsItem CommunicationModule'><svg width="151" height="99" viewBox="0 0 151 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.33" d="M115.337 -33.2328C129.655 -20.6647 142.403 -6.91837 147.895 10.3627C153.583 27.4475 151.622 48.067 140.638 60.0459C129.655 71.8285 109.649 74.9705 90.8198 82.2364C71.9908 89.5023 54.1425 100.892 38.2555 98.732C22.1724 96.7682 7.85456 81.4509 -0.775392 63.9734C-9.40534 46.2996 -12.3474 26.8583 -8.0324 9.57723C-3.71742 -7.70387 7.65843 -22.8248 21.7802 -35.3929C35.7058 -48.1574 52.5734 -58.5653 68.8526 -57.9762C85.1318 -57.387 100.823 -45.9972 115.337 -33.2328Z" fill="#E8CF8A" />
              </svg>
                <div className='addonimg'><img src='/images/Parent-Communication-Module.svg' /></div>
                <h5>Parent Communication Module</h5>
                <p>Parent Communication Module Keep parents in the loop with <strong>SMS</strong> and <strong>email</strong> notifications.</p>
              </div>

              <div className='AddOnsItem DigitalNoticeBoard'>
                <svg width="151" height="99" viewBox="0 0 151 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.33" d="M115.337 -33.2328C129.655 -20.6647 142.403 -6.91837 147.895 10.3627C153.583 27.4475 151.622 48.067 140.638 60.0459C129.655 71.8285 109.649 74.9705 90.8198 82.2364C71.9908 89.5023 54.1425 100.892 38.2555 98.732C22.1724 96.7682 7.85456 81.4509 -0.775392 63.9734C-9.40534 46.2996 -12.3474 26.8583 -8.0324 9.57723C-3.71742 -7.70387 7.65843 -22.8248 21.7802 -35.3929C35.7058 -48.1574 52.5734 -58.5653 68.8526 -57.9762C85.1318 -57.387 100.823 -45.9972 115.337 -33.2328Z" fill="#F1C9C1" />
                </svg>
                <div className='addonimg'><img src='/images/Digital-Notice-Board.svg' /></div>
                <h5>Digital Notice Board</h5>
                <p>Share announcements easily with students and faculty.</p>
              </div>

              <div className='AddOnsItem CustomAPIIntegration'>
                <svg width="151" height="99" viewBox="0 0 151 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.33" d="M115.337 -33.2328C129.655 -20.6647 142.403 -6.91837 147.895 10.3627C153.583 27.4475 151.622 48.067 140.638 60.0459C129.655 71.8285 109.649 74.9705 90.8198 82.2364C71.9908 89.5023 54.1425 100.892 38.2555 98.732C22.1724 96.7682 7.85456 81.4509 -0.775392 63.9734C-9.40534 46.2996 -12.3474 26.8583 -8.0324 9.57723C-3.71742 -7.70387 7.65843 -22.8248 21.7802 -35.3929C35.7058 -48.1574 52.5734 -58.5653 68.8526 -57.9762C85.1318 -57.387 100.823 -45.9972 115.337 -33.2328Z" fill="#E0C4FF" />
                </svg>
                <div className='addonimg'><img src='/images/custom-api.svg' /></div>
                <h5>Custom API Integration</h5>
                <p>Seamlessly connect MyRollCall.pro with your existing systems.</p>

              </div>

              <div className='AddOnsItem SmartTimetableFeature'>
                <svg width="151" height="99" viewBox="0 0 151 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.33" d="M115.337 -33.2328C129.655 -20.6647 142.403 -6.91837 147.895 10.3627C153.583 27.4475 151.622 48.067 140.638 60.0459C129.655 71.8285 109.649 74.9705 90.8198 82.2364C71.9908 89.5023 54.1425 100.892 38.2555 98.732C22.1724 96.7682 7.85456 81.4509 -0.775392 63.9734C-9.40534 46.2996 -12.3474 26.8583 -8.0324 9.57723C-3.71742 -7.70387 7.65843 -22.8248 21.7802 -35.3929C35.7058 -48.1574 52.5734 -58.5653 68.8526 -57.9762C85.1318 -57.387 100.823 -45.9972 115.337 -33.2328Z" fill="#B2E8BE" />
                </svg>
                <div className='addonimg'><img src='/images/smart-timetable.svg' /></div>
                <h5>Smart Timetable Feature</h5>
                <p>Streamline attendance based on class schedules.</p>
              </div>
            </div>
            <p className='Contactus'>Interested in add-ons? <span>Contact us</span> to learn more about pricing and integration.</p>

          </div>

        </div>
      </div>

      <div className='dots-shap'>
        <svg width="129" height="92" viewBox="0 0 129 92" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="46" r="10" fill="#F4E5D6" />
          <circle cx="5" cy="10" r="10" fill="#F4E5D6" />
          <circle cx="5" cy="82" r="10" fill="#F4E5D6" />
          <circle cx="43" cy="46" r="10" fill="#F4E5D6" />
          <circle cx="43" cy="10" r="10" fill="#F4E5D6" />
          <circle cx="43" cy="82" r="10" fill="#F4E5D6" />
          <circle cx="81" cy="46" r="10" fill="#F4E5D6" />
          <circle cx="81" cy="10" r="10" fill="#F4E5D6" />
          <circle cx="81" cy="82" r="10" fill="#F4E5D6" />
          <circle cx="119" cy="46" r="10" fill="#F4E5D6" />
          <circle cx="119" cy="10" r="10" fill="#F4E5D6" />
          <circle cx="119" cy="82" r="10" fill="#F4E5D6" />
        </svg>
      </div>
      <div className='owlShap'>
        <svg width="295" height="390" viewBox="0 0 295 390" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.33" d="M311.395 61.5037C346.968 92.7136 378.642 126.849 392.286 169.763C406.418 212.189 401.545 263.393 374.256 293.14C346.968 322.399 297.264 330.202 250.484 348.245C203.704 366.288 159.36 394.572 119.889 389.208C79.9315 384.331 44.3592 346.294 22.9183 302.893C1.47741 259.004 -5.83198 210.726 4.88845 167.813C15.6089 124.899 43.8719 87.3494 78.9569 56.1395C113.555 24.4419 155.462 -1.40377 195.907 0.0591989C236.352 1.52216 275.336 29.8061 311.395 61.5037Z" fill="#B7CDFF" />
        </svg>
      </div>


    </>




  );
}

export default PricePage;
