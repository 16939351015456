import React, { useEffect, useState } from 'react'
import FinalQuestion from '../../Components/InstituteDetails/finalQuestion';
import { checkEmailRegistered } from '../../Components/common/checkEmailVerify';
import { useLocation, useNavigate } from 'react-router-dom';

const UserDetails = () => {
    const [loading, setLoading] = useState(false);
    const [checkEmailRegState, setcheckEmailRegState] = useState(null)
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const userEmail = queryParams.get('user-email');
    const [useEmailRegister, setUseEmailRegister] = useState(false)
    const [userOnboarding, setUserOnboarding] = useState(false)
    const [questionnaireCompleted, setQuestionnaireCompleted] = useState(false)

    const checkEmailReg = async (email) => {
        setLoading(true);
        const checkEmailReg = await checkEmailRegistered(email);
        setcheckEmailRegState(checkEmailReg)
        if (checkEmailReg.data.onboarding_completed) {
            setUserOnboarding(true)
        } else {
            setUserOnboarding(false)
        }
        if (checkEmailReg.data.is_registered) {
            setUseEmailRegister(true)
        } else {
            setUseEmailRegister(false)
        }
        if (checkEmailReg.data.questionnaire_completed) {
            setQuestionnaireCompleted(true)
        } else {
            setQuestionnaireCompleted(false)
        }
        setLoading(false);
    }
    useEffect(() => {
        if (userEmail) {
            checkEmailReg(userEmail);
        }
    }, [userEmail]);

    if (loading) {
        return <div className='loading-state'>Loading...</div>
    }
    if (useEmailRegister) {
        return (<FinalQuestion  checkEmailRegState={checkEmailRegState} userEmail={userEmail} userOnboarding={userOnboarding} questionnaireCompleted={questionnaireCompleted}/>
        )
    }
    navigate("/");
}

export default UserDetails