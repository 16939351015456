import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { motion } from "framer-motion";
import '../App.css';
import { useLocation } from 'react-router-dom';
import { maskEmail } from './common/maskEmail';
import { checkEmailRegistered } from './common/checkEmailVerify';
import { useNavigate } from "react-router-dom"
import VerifyEmailPopup from './common/VerifyEmailPopup';
import { baseUrl } from './api/baseUrl';

const MainLogo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const userEmail = queryParams.get('user-email');
  const [isSticky, setIsSticky] = useState(false);

  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailverified, setEmailverified] = useState(false)
  const [useEmailRegister, setUseEmailRegister] = useState(false)
  const [verifyEmailModal, setVerifyEmailModal] = useState(false)


  const checkEmailReg = async (email) => {
    setLoading(true);
    const checkEmailReg = await checkEmailRegistered(email);
    if (checkEmailReg.data.is_registered) {
      setUseEmailRegister(true)
    } else {
      setUseEmailRegister(false)
    }

    console.log("checkEmailReg.data.is_email_verified", checkEmailReg.data.is_email_verified)

    if (checkEmailReg.data.is_email_verified === true) {
      setEmailverified(true)
    }
    if (checkEmailReg.data.is_email_verified === false) {
      setEmailverified(false)
    }
    setLoading(false);



    // else {
    //   alert("2")
    //   setEmailverified(false)
    // }


    console.log("isEmailverified", emailverified)
  }

  useEffect(() => {
    const handleScroll = () => {
      // Check if window has scrolled beyond the height of the header
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Add scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the scroll event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (userEmail) {
      checkEmailReg(userEmail)
    }
    // else{
    //   navigate('/')
    // }

  }, [userEmail])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scrolling smooth
    });
  }, [])

  const navigations = [
    { id: 1, name: "Pricing", href: "/pricing", className: "" },
    { id: 2, name: "Book a Demo", href: "/demo", className: "" },
    { id: 3, name: "Get App", href: "/download-app", className: "getapp" },
  ];
  const shouldRenderNav = location.pathname === "/" || location.pathname === "/pricing" || location.pathname === "/demo" || location.pathname === "/download-app";


  //
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    // Add or remove the class from the body tag based on the state
    if (isToggled) {
      document.body.classList.add('mobileMenu');
    } else {
      document.body.classList.remove('mobileMenu');
    }

    // Cleanup function to remove the class when component unmounts
    return () => {
      document.body.classList.remove('mobileMenu');
    };
  }, [isToggled]); // Effect runs when `isToggled` changes

  const handleLogoClick = () => {
    if (location.pathname === "/" || location.pathname === "/home") {
      window.location.reload(); // Reload the page
      window.scrollTo(0, 0); // Scroll to the top of the page

    } else {
      navigate('/')
    }
  }

  const handleResendEmail = async () => {
    setEmailLoading(true)
    try {
      const myHeaders = {
        "Content-Type": "application/json",
        "x-api-key": "w1sxc-d35fr-tg5bn-ji2k1"
      };

      const data = {
        "email_or_phone_no": userEmail
      };
      const res = await baseUrl.post(`auth/send-otp`, data, {
        headers: myHeaders
      });
      console.log("resresresresres", res)
      setVerifyEmailModal(true)
    } catch (error) {
      console.log("error", error)
      alert("Server serror: please try after sometime")
    } finally {
      setEmailLoading(false)
    }

  }

  const handleClose = () => {
    setVerifyEmailModal(false)
  }
  const handleVerifyOtpEmail = () => {
    setVerifyEmailModal(false);
    setEmailverified(true)
    // window.location.reload();
  }



  return (
    <>
      {
        !emailverified && !loading && userEmail && location.pathname === "/institute-details" && (
          <div className='userEmail'>
            <p className='text-center'>We've sent a verification link to {maskEmail(userEmail)}{". Didn't receive it? "}
             {
              emailLoading ?  <button type='button'className='Resendemail'><span className='loading-state Small'></span></button> :  <button type='button' onClick={handleResendEmail} className='Resendemail'>Resend email</button>
             }
            </p>
          </div>
        )
      }

      {/* {
        !emailverified && !loading && userEmail && location.pathname === "/institute-address" && (
          <div className='userEmail'>
            <p className='text-center'>We've sent a verification link to {maskEmail(userEmail)}. Didn't receive it? <a href="#" className='Resendemail'>Resend email</a></p>
          </div>
        )
      } */}

      {/* {
        !emailverified && !loading && userEmail && location.pathname === "/general-questionaire" && (
          <div className='userEmail'>
            <p className='text-center'>We've sent a verification link to {maskEmail(userEmail)}. Didn't receive it? <a href="#" className='Resendemail'>Resend email</a></p>
          </div>
        )
      } */}

      {/* {
        !emailverified && !loading && userEmail && location.pathname === "/user-details" && (
          <div className='userEmail'>
            <p className='text-center'>We've sent a verification link to {maskEmail(userEmail)}. Didn't receive it? <a href="#" className='Resendemail'>Resend email</a></p>
          </div>
        )
      } */}
      <header id='header-main' className={isSticky ? 'sticky' : ''}>
        <motion.div initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.15, duration: 0.3, ease: 'linear' } }} className='container mx-auto px-4 main-logo md:text-left text-center'>
          <div className='header'>
            <div className='logo'><span className='inline-block'><img src="/images/RollCall-logo.png" alt="Logo" onClick={handleLogoClick} /></span></div>
            <div className='mobile-toggle-button' onClick={handleToggle}>
              <img className='mobile-open' src='./images/mobile-open-icon.svg' />
              <img className='mobile-close' src='./images/mobile-close-icon.svg' />
            </div>
            <div className='navigation'>
              <nav>
                <ul>
                  {
                    shouldRenderNav &&
                    navigations.map((item) => (
                      <li key={item.id}>
                        <NavLink
                          to={item.href}
                          className={({ isActive }) => isActive ? `active ${item.className}` : item.className}
                        >
                          {item.name}
                        </NavLink>
                      </li>
                    ))
                  }
                  {/* <li><Link to="">Demo</Link></li>
                <li><Link to="/pricing">Pricing</Link></li>
                <li><Link to="" className='getapp'>Get App</Link></li> */}
                </ul>
              </nav>
            </div>
          </div>
        </motion.div>

        {verifyEmailModal && <VerifyEmailPopup email={userEmail} handleClose={handleClose} handleVerifyOtpEmail={handleVerifyOtpEmail} />}
      </header>
    </>

  );
};

export default MainLogo;
