import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import StarRatings from 'react-star-ratings'
import './style.css'


const HomeSlider = () => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1
        }
    };


    const clientArr = [
        {
            id: 1,
            img: "/images/client-1.png",
            name: "Dr. Ananya Rao",
            role: "Dean, Meridian College",
            desc: "Implementing myrollcall.pro at Meridian College has been a game-changer. The instant pin-based attendance system is incredibly efficient, saving us valuable time during lectures. Our attendance records are now accurate and reliable, thanks to the robust verification methods. Since adopting this system, we've noticed a significant improvement in student attendance rates as the chances of proxies have been drastically reduced. It's a must-have for any educational institution.",
            rating: 5
        },
        {
            id: 2,
            img: "/images/client-2.png",
            name: "Mr. Rajesh Kapoor",
            role: "Lecturer, Zenith University",
            desc: "myrollcall.pro has transformed the way we handle attendance at Zenith University. The quick and easy check-in process is a lifesaver, especially for large lecture halls. The integration with our existing systems was seamless, thanks to the customizable APIs. The detailed analytics provide us with valuable insights into student attendance patterns, helping us to address issues more proactively. It's an intuitive and effective solution that I highly recommend.",
            rating: 5
        },
        {
            id: 3,
            img: "/images/client-3.png",
            name: "Ms. Priya Sharma",
            role: "Administrator, Sunshine College",
            desc: "We completed the pilot version of myrollcall.pro at Sunshine College, and it has made a huge difference. The fast and simple attendance marking system ensures our classes start on time without any fuss. The detailed reports and analytics help us monitor attendance trends and take necessary actions. Parents appreciate the communication features that keep them informed about their children's attendance. It's a fantastic tool for any small tuition institute looking to streamline their processes.",
            rating: 5
        }
    ]
    return (
        <Carousel
            responsive={responsive}
            showDots={true}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            autoPlay={true}
            itemClass="carousel-item-padding"
        >

            {
                clientArr.map((item) => (
                    <div key={item.id} className='bg-white shadow-2xl md:col-span-4 col-span-12 text-center p-6 rounded-2xl md:mb-0 mb-5 wow animate__animated animate__fadeInUp' data-wow-duration="0.5s" data-wow-delay="0.2s">
                        <div className='w-24 h-24 rounded-full overflow-hidden m-auto mt-8'>
                            <img src={item.img} alt='' />
                        </div>
                        <h4 className='text-regal-blue text-xl font-semibold mt-6'>{item.name}</h4>
                        <h4 className='text-regal-blue text-base font-light'>{item.role}</h4>
                        <p className='text-gray-400 text-base font-light mt-4 mb-4 md:max-w-80 w-full m-auto line-clamp-5 hover:line-clamp-none'>{item.desc}</p>
                        <div className='flex gap-3 justify-center my-5 mt-8'>
                            {<StarRatings
                                rating={item.rating}
                                starDimension="20px"
                                starSpacing="5px"
                                starRatedColor="orange"
                            />}
                        </div>
                    </div>
                ))
            }
        </Carousel>
    )
}

export default HomeSlider