import React from 'react'
import { useNavigate } from "react-router-dom";
const NewRegisterThankuPopup = ({ userEmail,handleNewThankuSectionClose }) => {

    const navigate = useNavigate();
    const handleContinue = () => {
        navigate(`institute-details?user-email=${userEmail}`);
        handleNewThankuSectionClose();
    }
    return (
        <div className='fullsection emailCollectionSection'>
            <div>
                <div className="bg-white shadow-2xl p-6 rounded-2xl md:mb-0 mb-5 grid grid-cols-12 md:gap-10 gap-0 md:max-w-screen-sm relative m-auto w-full">
                    <div className="md:col-span-12 col-span-12">
                        <div className="ShowThankyou">
                            <div className="text-center">
                                <img src="/images/thanku-for-your-information.svg" alt="" className="m-auto max-w-60" />
                                <p className="text-center text-xl text-regal-blue mb-3 mt-4 max-w-2xl mx-auto font-bold">
                                    Thank You for Your Information!
                                </p>
                                <p className="text-center text-lg text-regal-blue mb-5 max-w-sm mx-auto font-normal">
                                    We have a few more questions to better understand your institution's needs.
                                </p>

                                <div className="flex justify-center items-center gap-4 mt-4 thankyubtns">
                                    <button type="button" onClick={handleContinue} className="inline-flex gap-4 bg-blue-600 text-white md:px-6 lg:px-12 px-8 py-3 text-base rounded-lg shadow-lg items-center shadow-blue-300 hover:bg-regal-blue transition ease-in-out duration-300">
                                        Continue Now
                                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.64012 6.99996L17.3599 6.99996M17.3599 6.99996L11.6934 1.33496M17.3599 6.99996L11.6934 12.665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </button>
                                    <span>or</span>
                                    <button type='button' onClick={handleNewThankuSectionClose} className="inline-flex gap-4 text-blue-600 md:px-6 lg:px-6 px-3 py-3 text-base rounded-lg items-center shadow-none border border-gray-300 hover:bg-gray-300 transition ease-in-out duration-300">
                                        Later
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <span className="close-panel absolute top-4 right-4 cursor-pointer" onClick={handleNewThankuSectionClose}>
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5 1L1.5 11" stroke="#455A64" strokeWidth="2" strokeLinecap="round"></path>
                            <path d="M11.5 11L1.5 1" stroke="#455A64" strokeWidth="2" strokeLinecap="round"></path>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default NewRegisterThankuPopup