import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage/index';
import MainLogo from './Components/Logo';
import UploadDocument from './Pages/UploadDocument/index';
import PricePage from './Pages/PricePage';
import Demo from './Pages/Demo';
import InstituteDetails from './Pages/institute-details';
import Faq from './Pages/faq';
import PrivacyPolicy from './Pages/privacy-policy';
import DownloadApp from './Pages/DownloadApp';
import InstituteAddress from './Pages/institute-address';
import GeneralQuestionnaire from './Pages/GeneralQuestionnaire';
import UserDetails from './Pages/user-details';



function App() {
  return (
      <BrowserRouter>
          <MainLogo />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/institute-details" element={<InstituteDetails />} />
              <Route path="/institute-address" element={<InstituteAddress />} />
              <Route path="/general-questionaire" element={<GeneralQuestionnaire />} />
              <Route path="/user-details" element={<UserDetails />} />
              <Route path="/UploadDocument" element={<UploadDocument />} />
              <Route path="/pricing" element={<PricePage />} />
              <Route path="/demo" element={<Demo />} />
              <Route path="/frequently-asked-questions" element={<Faq />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path='/download-app' element={<DownloadApp />} ></Route>
            </Routes>
      </BrowserRouter>
  );
}

export default App;
